import React from 'react'

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link, useNavigate } from 'react-router-dom'

import HeadedPage from '../../components/HeadedPage'

import ModuleBuilder from './ModuleBuilder'

export default function PathBuilderPage () {
  const navigate = useNavigate()

  const onSave = (res) => {
    navigate(`/modules/edit/${res.data.code}`)
  }

  const vars = {
    '--bs-link-color': 'white',
    '--bs-link-hover-color': '#c882bd',
    '--bs-breadcrumb-item-active-color': 'white'
  }

  return (
    <HeadedPage
      title='Module Builder'
      headerContents={(
        <>
          <h1>Module Builder</h1>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/modules' }} style={vars}>Modules</Breadcrumb.Item>
            <Breadcrumb.Item active style={vars}>Builder</Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
    >
      <ModuleBuilder onSave={onSave} />
    </HeadedPage>
  )
}
