import React from 'react'

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'

import HeadedPage from '../../components/HeadedPage'

import PathBuilder from './PathBuilder'

export default function PathBuilderPage () {
  const vars = {
    '--bs-link-color': 'white',
    '--bs-link-hover-color': '#c882bd',
    '--bs-breadcrumb-item-active-color': 'white'
  }

  return (
    <HeadedPage
      title='Path Builder'
      headerContents={(
        <>
          <h1>Path Builder</h1>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/paths' }} style={vars}>Paths</Breadcrumb.Item>
            <Breadcrumb.Item active style={vars}>Builder</Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
    >
      <PathBuilder />
    </HeadedPage>
  )
}
