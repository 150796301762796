import React from 'react'

import { GripHorizontal } from 'react-bootstrap-icons'

export default function Grip (props) {
  return (
    <div style={{ cursor: 'row-resize', touchAction: 'none' }} {...props}>
      <GripHorizontal size={32} color='var(--bs-secondary)' aria-hidden />
    </div>
  )
}
