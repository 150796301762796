const PREFERRED_CURRENCY_CODES = [
  'GBP',
  'USD',
  'EUR'
]

const iso4712 = [
  { code: 'AED', currency: 'United Arab Emirates dirham' },
  { code: 'AFN', currency: 'Afghan afghani' },
  { code: 'ALL', currency: 'Albanian lek' },
  { code: 'AMD', currency: 'Armenian dram' },
  { code: 'ANG', currency: 'Netherlands Antillean guilder' },
  { code: 'AOA', currency: 'Angolan kwanza' },
  { code: 'ARS', currency: 'Argentine peso' },
  { code: 'AUD', currency: 'Australian dollar' },
  { code: 'AWG', currency: 'Aruban florin' },
  { code: 'AZN', currency: 'Azerbaijani manat' },
  { code: 'BAM', currency: 'Bosnia and Herzegovina convertible mark' },
  { code: 'BBD', currency: 'Barbados dollar' },
  { code: 'BDT', currency: 'Bangladeshi taka' },
  { code: 'BGN', currency: 'Bulgarian lev' },
  { code: 'BHD', currency: 'Bahraini dinar' },
  { code: 'BIF', currency: 'Burundian franc' },
  { code: 'BMD', currency: 'Bermudian dollar' },
  { code: 'BND', currency: 'Brunei dollar' },
  { code: 'BOB', currency: 'Boliviano' },
  { code: 'BOV', currency: 'Bolivian Mvdol (funds code)' },
  { code: 'BRL', currency: 'Brazilian real' },
  { code: 'BSD', currency: 'Bahamian dollar' },
  { code: 'BTN', currency: 'Bhutanese ngultrum' },
  { code: 'BWP', currency: 'Botswana pula' },
  { code: 'BYN', currency: 'Belarusian ruble' },
  { code: 'BZD', currency: 'Belize dollar' },
  { code: 'CAD', currency: 'Canadian dollar' },
  { code: 'CDF', currency: 'Congolese franc' },
  { code: 'CHE', currency: 'WIR euro (complementary currency)' },
  { code: 'CHF', currency: 'Swiss franc' },
  { code: 'CHW', currency: 'WIR franc (complementary currency)' },
  { code: 'CLF', currency: 'Unidad de Fomento (funds code)' },
  { code: 'CLP', currency: 'Chilean peso' },
  { code: 'COP', currency: 'Colombian peso' },
  { code: 'COU', currency: 'Unidad de Valor Real (UVR) (funds code)' },
  { code: 'CRC', currency: 'Costa Rican colon' },
  { code: 'CUC', currency: 'Cuban convertible peso' },
  { code: 'CUP', currency: 'Cuban peso' },
  { code: 'CVE', currency: 'Cape Verdean escudo' },
  { code: 'CZK', currency: 'Czech koruna' },
  { code: 'DJF', currency: 'Djiboutian franc' },
  { code: 'DKK', currency: 'Danish krone' },
  { code: 'DOP', currency: 'Dominican peso' },
  { code: 'DZD', currency: 'Algerian dinar' },
  { code: 'EGP', currency: 'Egyptian pound' },
  { code: 'ERN', currency: 'Eritrean nakfa' },
  { code: 'ETB', currency: 'Ethiopian birr' },
  { code: 'EUR', currency: 'Euro' },
  { code: 'FJD', currency: 'Fiji dollar' },
  { code: 'FKP', currency: 'Falkland Islands pound' },
  { code: 'GBP', currency: 'Pound sterling' },
  { code: 'GEL', currency: 'Georgian lari' },
  { code: 'GHS', currency: 'Ghanaian cedi' },
  { code: 'GIP', currency: 'Gibraltar pound' },
  { code: 'GMD', currency: 'Gambian dalasi' },
  { code: 'GNF', currency: 'Guinean franc' },
  { code: 'GTQ', currency: 'Guatemalan quetzal' },
  { code: 'GYD', currency: 'Guyanese dollar' },
  { code: 'HKD', currency: 'Hong Kong dollar' },
  { code: 'HNL', currency: 'Honduran lempira' },
  { code: 'HTG', currency: 'Haitian gourde' },
  { code: 'HUF', currency: 'Hungarian forint' },
  { code: 'IDR', currency: 'Indonesian rupiah' },
  { code: 'ILS', currency: 'Israeli new shekel' },
  { code: 'INR', currency: 'Indian rupee' },
  { code: 'IQD', currency: 'Iraqi dinar' },
  { code: 'IRR', currency: 'Iranian rial' },
  { code: 'ISK', currency: 'Icelandic króna (plural: krónur)' },
  { code: 'JMD', currency: 'Jamaican dollar' },
  { code: 'JOD', currency: 'Jordanian dinar' },
  { code: 'JPY', currency: 'Japanese yen' },
  { code: 'KES', currency: 'Kenyan shilling' },
  { code: 'KGS', currency: 'Kyrgyzstani som' },
  { code: 'KHR', currency: 'Cambodian riel' },
  { code: 'KMF', currency: 'Comoro franc' },
  { code: 'KPW', currency: 'North Korean won' },
  { code: 'KRW', currency: 'South Korean won' },
  { code: 'KWD', currency: 'Kuwaiti dinar' },
  { code: 'KYD', currency: 'Cayman Islands dollar' },
  { code: 'KZT', currency: 'Kazakhstani tenge' },
  { code: 'LAK', currency: 'Lao kip' },
  { code: 'LBP', currency: 'Lebanese pound' },
  { code: 'LKR', currency: 'Sri Lankan rupee' },
  { code: 'LRD', currency: 'Liberian dollar' },
  { code: 'LSL', currency: 'Lesotho loti' },
  { code: 'LYD', currency: 'Libyan dinar' },
  { code: 'MAD', currency: 'Moroccan dirham' },
  { code: 'MDL', currency: 'Moldovan leu' },
  { code: 'MGA', currency: 'Malagasy ariary' },
  { code: 'MKD', currency: 'Macedonian denar' },
  { code: 'MMK', currency: 'Myanmar kyat' },
  { code: 'MNT', currency: 'Mongolian tögrög' },
  { code: 'MOP', currency: 'Macanese pataca' },
  { code: 'MRU', currency: 'Mauritanian ouguiya' },
  { code: 'MUR', currency: 'Mauritian rupee' },
  { code: 'MVR', currency: 'Maldivian rufiyaa' },
  { code: 'MWK', currency: 'Malawian kwacha' },
  { code: 'MXN', currency: 'Mexican peso' },
  { code: 'MXV', currency: 'Mexican Unidad de Inversion (UDI) (funds code)' },
  { code: 'MYR', currency: 'Malaysian ringgit' },
  { code: 'MZN', currency: 'Mozambican metical' },
  { code: 'NAD', currency: 'Namibian dollar' },
  { code: 'NGN', currency: 'Nigerian naira' },
  { code: 'NIO', currency: 'Nicaraguan córdoba' },
  { code: 'NOK', currency: 'Norwegian krone' },
  { code: 'NPR', currency: 'Nepalese rupee' },
  { code: 'NZD', currency: 'New Zealand dollar' },
  { code: 'OMR', currency: 'Omani rial' },
  { code: 'PAB', currency: 'Panamanian balboa' },
  { code: 'PEN', currency: 'Peruvian sol' },
  { code: 'PGK', currency: 'Papua New Guinean kina' },
  { code: 'PHP', currency: 'Philippine peso' },
  { code: 'PKR', currency: 'Pakistani rupee' },
  { code: 'PLN', currency: 'Polish złoty' },
  { code: 'PYG', currency: 'Paraguayan guaraní' },
  { code: 'QAR', currency: 'Qatari riyal' },
  { code: 'RON', currency: 'Romanian leu' },
  { code: 'RSD', currency: 'Serbian dinar' },
  { code: 'CNY', currency: 'Renminbi' },
  { code: 'RUB', currency: 'Russian ruble' },
  { code: 'RWF', currency: 'Rwandan franc' },
  { code: 'SAR', currency: 'Saudi riyal' },
  { code: 'SBD', currency: 'Solomon Islands dollar' },
  { code: 'SCR', currency: 'Seychelles rupee' },
  { code: 'SDG', currency: 'Sudanese pound' },
  { code: 'SEK', currency: 'Swedish krona (plural: kronor)' },
  { code: 'SGD', currency: 'Singapore dollar' },
  { code: 'SHP', currency: 'Saint Helena pound' },
  { code: 'SLE', currency: 'Sierra Leonean leone (new leone)' },
  { code: 'SLL', currency: 'Sierra Leonean leone (old leone)' },
  { code: 'SOS', currency: 'Somali shilling' },
  { code: 'SRD', currency: 'Surinamese dollar' },
  { code: 'SSP', currency: 'South Sudanese pound' },
  { code: 'STN', currency: 'São Tomé and Príncipe dobra' },
  { code: 'SVC', currency: 'Salvadoran colón' },
  { code: 'SYP', currency: 'Syrian pound' },
  { code: 'SZL', currency: 'Swazi lilangeni' },
  { code: 'THB', currency: 'Thai baht' },
  { code: 'TJS', currency: 'Tajikistani somoni' },
  { code: 'TMT', currency: 'Turkmenistan manat' },
  { code: 'TND', currency: 'Tunisian dinar' },
  { code: 'TOP', currency: 'Tongan paʻanga' },
  { code: 'TRY', currency: 'Turkish lira' },
  { code: 'TTD', currency: 'Trinidad and Tobago dollar' },
  { code: 'TWD', currency: 'New Taiwan dollar' },
  { code: 'TZS', currency: 'Tanzanian shilling' },
  { code: 'UAH', currency: 'Ukrainian hryvnia' },
  { code: 'UGX', currency: 'Ugandan shilling' },
  { code: 'USD', currency: 'United States dollar' },
  { code: 'USN', currency: 'United States dollar (next day) (funds code)' },
  { code: 'UYI', currency: 'Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)' },
  { code: 'UYU', currency: 'Uruguayan peso' },
  { code: 'UYW', currency: 'Unidad previsional' },
  { code: 'UZS', currency: 'Uzbekistan sum' },
  { code: 'VED', currency: 'Venezuelan digital bolívar' },
  { code: 'VES', currency: 'Venezuelan sovereign bolívar' },
  { code: 'VND', currency: 'Vietnamese đồng' },
  { code: 'VUV', currency: 'Vanuatu vatu' },
  { code: 'WST', currency: 'Samoan tala' },
  { code: 'XAF', currency: 'CFA franc BEAC' },
  { code: 'XAG', currency: 'Silver (one troy ounce)' },
  { code: 'XAU', currency: 'Gold (one troy ounce)' },
  { code: 'XBA', currency: 'European Composite Unit (EURCO) (bond market unit)' },
  { code: 'XBB', currency: 'European Monetary Unit (E.M.U.-6) (bond market unit)' },
  { code: 'XBC', currency: 'European Unit of Account 9 (E.U.A.-9) (bond market unit)' },
  { code: 'XBD', currency: 'European Unit of Account 17 (E.U.A.-17) (bond market unit)' },
  { code: 'XCD', currency: 'East Caribbean dollar' },
  { code: 'XDR', currency: 'Special drawing rights' },
  { code: 'XOF', currency: 'CFA franc BCEAO' },
  { code: 'XPD', currency: 'Palladium (one troy ounce)' },
  { code: 'XPF', currency: 'CFP franc (franc Pacifique)' },
  { code: 'XPT', currency: 'Platinum (one troy ounce)' },
  { code: 'XSU', currency: 'SUCRE' },
  { code: 'XTS', currency: 'Code reserved for testing' },
  { code: 'XUA', currency: 'ADB Unit of Account' },
  { code: 'XXX', currency: 'No currency' },
  { code: 'YER', currency: 'Yemeni rial' },
  { code: 'ZAR', currency: 'South African rand' },
  { code: 'ZMW', currency: 'Zambian kwacha' },
  { code: 'ZWL', currency: 'Zimbabwean dollar (fifth)' }
]

export default iso4712

export const preferredCurrencies = PREFERRED_CURRENCY_CODES.map(code => iso4712.find(cur => cur.code === code))
