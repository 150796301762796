import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import BSButton from 'react-bootstrap/Button'

const forcedLightVariants = [
  'green',
  'success',
  'secondary'
]

const Button = forwardRef(({ variant, children, className, style: styleProp, ...props }, ref) => {
  const style = {
    color: forcedLightVariants.includes(variant) ? '#fff' : undefined,
    ...styleProp
  }

  return (
    <BSButton
      ref={ref}
      variant={variant}
      {...props}
      className={`d-flex gap-2 align-items-center justify-content-center fw-bold ${className}`}
      style={style}
    >
      { children && children }
    </BSButton>
  )
})

Button.displayName = 'Button'

Button.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object
}

Button.defaultProps = {
  variant: 'primary',
  className: '',
  children: undefined,
  style: {}
}

export default Button
