import React from 'react'
import PropTypes from 'prop-types'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons'

import IconButton from './IconButton'

export default function BumpButton ({ dir, onClick }) {
  return (
    <OverlayTrigger
      delay={250}
      overlay={(
        <Tooltip id='tooltip-code-lock' aria-hidden>
          { dir === 'up' ? 'Move up' : 'Move down' }
        </Tooltip>
      )}
    >
      <IconButton
        variant='outline-secondary'
        icon={dir === 'up' ? <CaretUpFill size={14} aria-hidden /> : <CaretDownFill size={14} aria-hidden />}
        className='p-1 d-none d-sm-flex'
        aria-label={dir === 'up' ? 'Move up' : 'Move down'}
        onClick={onClick}
      />
    </OverlayTrigger>
  )
}

BumpButton.propTypes = {
  dir: PropTypes.oneOf(['up', 'down']).isRequired,
  onClick: PropTypes.func.isRequired
}
