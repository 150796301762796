import { PublicClientApplication } from '@azure/msal-browser'

// This is secure as it doesn't bypass security on the API side, it just doesn't send auth tokens
// with it. Only used in testing.
export const authEnabled = window.location.hash !== '#noAuth'
const debugTokens = process.env.NODE_ENV === 'development'

let _redirectUri = process.env.AUTH_REDIRECT_URL
if (_redirectUri === undefined) {
  if (process.env.NODE_ENV === 'production') {
    _redirectUri = `${process.env.PUBLIC_URL}/`
  } else {
    _redirectUri = 'http://localhost:3000/'
  }
}
export const redirectUri = _redirectUri

const clientId = process.env.AUTH_CLIENT_ID ?? '4f11f97f-0b6a-4d17-a4b3-f5a71f7c9aae'
export const msalInstance = new PublicClientApplication({
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${process.env.AUTH_TENANT_ID ?? '14e1d7e2-2c30-4e78-8b82-5fb14801c021'}/`,
    redirectUri
  }
})

/**
 * Get a JWT token to use during authentication.
 * @returns null if the user isn't signed in, or the token
 */
export const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts()

  if (!activeAccount && accounts.length === 0) {
    return null
  }

  const request = {
    scopes: [`api://${clientId}/api`],
    account: activeAccount || accounts[0]
  }

  const authResult = await msalInstance.acquireTokenSilent(request)

  if (debugTokens) {
    console.groupCollapsed('Tokens')
    console.log('Roles:')
    console.log(request.account.idTokenClaims.roles)
    console.log('Access token:')
    console.log(authResult.accessToken)
    console.log('ID token:')
    console.log(authResult.idToken)
    console.groupEnd()
  }

  return authResult.accessToken
}
