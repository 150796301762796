import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import { useNavigate } from 'react-router-dom'

const LinkIconButton = ({ icon, children, to, ...props }) => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(to)
  }

  return (
    <Button onClick={onClick} {...props}>
      { icon }
      { children && <span>{ children }</span> }
    </Button>
  )
}

LinkIconButton.displayName = 'IconButton'

LinkIconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string
}

LinkIconButton.defaultProps = {
  className: '',
  children: undefined,
  to: undefined
}

export default LinkIconButton
