import React from 'react'
import PropTypes from 'prop-types'

import { Pencil } from 'react-bootstrap-icons'

import IconButton from './IconButton'

export default function EditButton ({ onClick }) {
  return (
    <IconButton variant='outline-primary' icon={<Pencil aria-hidden />} onClick={onClick}>
      Edit
    </IconButton>
  )
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
