import React from 'react'
import { Link, useParams } from 'react-router-dom'

import Breadcrumb from 'react-bootstrap/Breadcrumb'

import HeadedPage from '../../components/HeadedPage'

import PathEditor from './PathEditor'

export default function PathEditorPage () {
  const { code } = useParams()

  const vars = {
    '--bs-link-color': 'white',
    '--bs-link-hover-color': '#c882bd',
    '--bs-breadcrumb-item-active-color': 'white'
  }

  return (
    <HeadedPage
      title={`[${code}] Path Editor`}
      headerContents={(
        <>
          <h1>Path Editor</h1>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/paths' }} style={vars}>Paths</Breadcrumb.Item>
            <Breadcrumb.Item active style={vars}>{ code }</Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
    >
      <PathEditor code={code} />
    </HeadedPage>
  )
}
