import React, { useState, useReducer, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import deepEqual from 'fast-deep-equal'

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import HeadedPage from '../../components/HeadedPage'

import QualificationForm from './QualificationForm'

import qualifications from '../../api/qualifications'
import QualificationDangerZone from './QualificationDangerZone'

export default function QualificationEditorPage () {
  const { code } = useParams()

  const queryClient = useQueryClient()

  const [isChanged, setChanged] = useState(false)
  const [isSaved, setSaved] = useState(false)
  const [formData, setFormData] = useState()

  const {
    isLoading, isError, isRefetching, isSuccess, data: qualificationData
  } = useQuery(['qualification', code], () => qualifications.get(code))

  const [data, update] = useReducer((state, patch) => {
    const nextState = { ...state, ...patch }
    setChanged(!deepEqual(formData, nextState))
    return nextState
  }, {})

  useEffect(() => {
    setFormData({
      ...qualificationData,
      typeName: qualificationData?.type,
      unitPrice: (qualificationData?.unitPrice.amount / 100).toFixed(2),
      unitPriceCurrency: qualificationData?.unitPrice.iso4217Currency,
      hasLink: qualificationData?.link !== null,
      link: qualificationData?.link ?? ''
    })
  }, [qualificationData])

  useEffect(() => {
    update(formData)
  }, [formData])

  const saveMutation = useMutation(
    (qualificationData) => qualifications.update(code, qualificationData),
    {
      onSuccess () {
        queryClient.invalidateQueries(['path', code])
      }
    }
  )

  const { isLoading: isSaving, error: saveError } = saveMutation

  const onSave = () => {
    const { code, typeName, name, description, canBuyInBulk, unitPriceCurrency } = data
    const link = data.hasLink ? data.link : null

    const unitPriceNormalised = Math.trunc(parseFloat(data.unitPrice, 10) * 100)

    const qualificationData = { code, typeName, name, description, canBuyInBulk, link, unitPrice: unitPriceNormalised, unitPriceCurrency }

    saveMutation
      .mutateAsync(qualificationData)
      .then(() => {
        setSaved(true)
        setTimeout(() => setSaved(false), 5000)
        setChanged(false)
      })
  }

  const vars = {
    '--bs-link-color': 'white',
    '--bs-link-hover-color': '#c882bd',
    '--bs-breadcrumb-item-active-color': 'white'
  }

  return (
    <HeadedPage
      headerContents={(
        <>
          <h1>Qualification Builder</h1>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/qualifications' }} style={vars}>Qualifications</Breadcrumb.Item>
            <Breadcrumb.Item active style={vars}>Builder</Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
    >
      { isLoading && (
        <div className='text-center'>
          <Spinner variant='secondary' size='sm' aria-label='Loading' />
        </div>
      ) }

      { isError && <Alert variant='danger'>Failed to load content.</Alert> }

      { isSuccess && data.name !== undefined && (
        <div className='rounded shadow bg-white p-4'>
          <QualificationForm
            editMode
            formData={data}
            update={update}
            isChanged={isChanged}
            onSave={onSave}
            isSaving={isSaving}
            isSaved={isSaved}
            saveError={saveError}
          />

          <QualificationDangerZone code={code} />

          { /* Overlay to prevent interaction & indicate inactivity during refetching */ }
          { /* Should reduce chance of layout shifts causing misclicks. */ }
          { isRefetching && <div className='position-absolute top-0 w-100 h-100 rounded bg-light opacity-50' style={{ left: '0' }} aria-hidden /> }
        </div>
      ) }
    </HeadedPage>
  )
}
