import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Alert from 'react-bootstrap/Alert'
import { Search } from 'react-bootstrap-icons'

import { useQuery } from 'react-query'

import modules from '../../api/modules'

import PropTypes from '../../prop-types'

export default function ModuleModal ({
  show, onClose, onAdd, blacklist
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedModule, setSelectedModule] = useState(null)

  const { isLoading, isError, data } = useQuery(['modules'], () => modules.list(false))

  const doClose = () => {
    setSearchTerm('')
    setSelectedModule(null)
    onClose()
  }

  const doAdd = () => {
    onAdd(selectedModule)
    doClose()
  }

  const moduleList = data
    ?._embedded
    ?.moduleList
    ?.filter((module) => {
      if (searchTerm === '') return true
      const searchText = (module.code + module.name).toUpperCase()
      return searchText.includes(searchTerm.toUpperCase())
    })
    ?.map((module) => {
      const blacklisted = blacklist.includes(module.code)
      const selected = module.code === selectedModule?.code
      let variant = 'outline-primary'
      if (blacklisted || selected) {
        variant = 'primary'
      }
      return { ...module, blacklisted, variant }
    })

  return (
    <Modal show={show} onHide={doClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Module
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isLoading && <Spinner /> }
        { isError && <Alert variant='danger'>Failed to fetch module list.</Alert> }
        { !isLoading && moduleList !== undefined && (
          <>
            <InputGroup className='mb-2'>
              <InputGroup.Text>
                <Search aria-hidden />
              </InputGroup.Text>
              <Form.Control type='text' value={searchTerm} placeholder='Search terms' onChange={({ target }) => setSearchTerm(target.value)} />
            </InputGroup>
            <div style={{ minHeight: '60vh', position: 'relative' }}>
              <div
                style={{
                  overflowY: 'auto',
                  width: '100%',
                  maxHeight: '100%',
                  position: 'absolute',
                  boxSizing: 'border-box'
                }}
              >
                <ButtonGroup vertical className='w-100'>
                  { moduleList.map((module) => (
                    <Button
                      key={module.code}
                      onClick={() => setSelectedModule(module)}
                      variant={module.variant}
                      disabled={module.blacklisted}
                    >
                      { `${module.code} - ${module.name}` }
                    </Button>
                  )) }
                </ButtonGroup>
              </div>
            </div>
          </>
        ) }
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={doClose}>
          Close
        </Button>
        <Button variant='primary' onClick={doAdd} disabled={selectedModule === null}>
          { selectedModule === null
            ? 'Add to path'
            : <>
                Add
                <strong>{ ` ${selectedModule.code} ` }</strong>
                to path
              </> }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ModuleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  blacklist: PropTypes.arrayOf(PropTypes.string).isRequired
}
