import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import router from './router'

import './index.scss'
import { NavMenuProvider } from './components/NavMenu'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './api/auth'
import { ToastProvider } from './components/Toaster'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <NavMenuProvider>
            <RouterProvider router={router} />
          </NavMenuProvider>
        </ToastProvider>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
)
