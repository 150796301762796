import React from 'react'
import PropTypes from 'prop-types'

import RestoreButton from './RestoreButton'
import ArchiveButton from './ArchiveButton'

export default function ArchiveOrRestoreButton ({
  isArchived, isArchiving, isRestoring, isError, error, onArchive, onRestore
}) {
  if (isArchived) {
    return <RestoreButton onClick={onRestore} isLoading={isRestoring} isError={isError} error={error} />
  }

  return <ArchiveButton onClick={onArchive} isLoading={isArchiving} isError={isError} error={error} />
}

ArchiveOrRestoreButton.propTypes = {
  isArchived: PropTypes.bool.isRequired,
  isArchiving: PropTypes.bool.isRequired,
  isRestoring: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  onArchive: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired
}

ArchiveOrRestoreButton.defaultProps = {
  isError: false,
  error: undefined
}
