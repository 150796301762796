import React, { useRef } from 'react'

import clone from 'clone'

import { useDraggableContext } from 'react-tiny-dnd'
import { useAutoScroll } from 'react-tiny-autoscroll'

import StagesEditorControls from './StagesEditorControls'

import ModuleCard from './ModuleCard'
import ModuleSuggestions from './ModuleSuggestions'

import PropTypes from '../../prop-types'

export default function StagesEditor ({ disabled, stages, onChange }) {
  const reflowStages = (nextStages) => {
    nextStages.forEach((stage, i) => {
      stage.position = i
    })
    nextStages.sort((a, b) => a.position - b.position)
    onChange(nextStages)
  }

  const onMove = (source, target) => {
    if (target < 0 || target > stages.length) return
    const nextStages = clone(stages)
    const [droppedStage] = nextStages.splice(source, 1)
    nextStages.splice(target, 0, droppedStage)
    reflowStages(nextStages)
  }

  const onRemove = (index) => {
    const nextStages = clone(stages)
    nextStages.splice(index, 1)
    reflowStages(nextStages)
  }

  const onAdd = (module) => {
    const nextStages = clone(stages)
    nextStages.push({
      module,
      position: nextStages.length
    })
    reflowStages(nextStages)
  }

  const context = useDraggableContext({
    onDrop: onMove
  })

  const containerRef = useRef(null)
  useAutoScroll({ containerRef, skip: !context.isDragging })

  return (
    <div className='mt-4'>
      <div className='d-flex align-items-center mb-2'>
        <h3 className='m-0 me-4'>Stages</h3>
        { !disabled && <StagesEditorControls stages={stages} onAdd={onAdd} className='ms-auto' /> }
      </div>

      <div ref={containerRef} className='bg-light rounded p-2'>
        { stages.length === 0 && <div className='p-2 text-muted fst-italic fs-5'>No stages to show.</div> }

        { stages.map((stage, i) => (
          <ModuleCard
            disabled={disabled}
            key={stage.module.code}
            stage={stage}
            context={context}
            index={i}
            onMove={(target) => onMove(i, target)}
            onRemove={() => onRemove(i)}
            className='pt-1 pb-2'
          />
        )) }
      </div>

      { stages.length === 0 && <ModuleSuggestions type='first' onAdd={onAdd} className='mt-3' /> }

      { stages.length > 0 && (
        <ModuleSuggestions
          type='next'
          after={stages[stages.length - 1].module.code}
          onAdd={onAdd}
          className='mt-3'
        />
      ) }

      { !disabled && <StagesEditorControls stages={stages} onAdd={onAdd} className='mt-3 justify-content-end' /> }
    </div>
  )
}

StagesEditor.propTypes = {
  disabled: PropTypes.bool,
  stages: PropTypes.arrayOf(PropTypes.pathStage).isRequired,
  onChange: PropTypes.func.isRequired
}

StagesEditor.defaultProps = {
  disabled: false
}
