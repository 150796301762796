import React from 'react'
import PropTypes from 'prop-types'

export default function MonetaryAmount ({ amount }) {
  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: amount.iso4217Currency,
    currencyDisplay: 'symbol'
  })

  const convertedCurrencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: amount.iso4217CurrencyConverted ?? amount.iso4217Currency,
    currencyDisplay: 'symbol'
  })

  const isConvertedAndNeedsDisplaying = amount.iso4217CurrencyConverted !== null && amount.iso4217CurrencyConverted !== amount.iso4217Currency

  return (
    <>
      {currencyFormatter.format(amount.amount / 100)}
      {isConvertedAndNeedsDisplaying && (
        <span className='ms-1 text-muted'>({convertedCurrencyFormatter.format(amount.amountConverted / 100)} approx)</span>
      )}
    </>
  )
}

MonetaryAmount.propTypes = {
  amount: PropTypes.object.isRequired
}
