import React, { useState } from 'react'

import { Plus } from 'react-bootstrap-icons'

import IconButton from '../../components/IconButton'
import Button from '../../components/Button'

import AddModuleModal from './AddModuleModal'
import NewModuleModal from './NewModuleModal'

import PropTypes from '../../prop-types'

export default function StagesEditorControls ({ stages, onAdd, className }) {
  const [showAddModuleModal, setShowAddModuleModal] = useState(false)
  const [showNewModuleModal, setShowNewModuleModal] = useState(false)

  return (
    <div className={`d-flex gap-2 ${className}`}>
      <IconButton icon={<Plus size={32} style={{ margin: '-4px' }} aria-hidden />} onClick={() => setShowAddModuleModal(true)}>Add Module</IconButton>
      <Button variant='dark' onClick={() => setShowNewModuleModal(true)}>New Module...</Button>

      <AddModuleModal
        show={showAddModuleModal}
        onClose={() => setShowAddModuleModal(false)}
        onAdd={onAdd}
        blacklist={stages.map((stage) => stage.module.code)}
      />

      <NewModuleModal
        show={showNewModuleModal}
        onClose={() => setShowNewModuleModal(false)}
        onAdd={onAdd}
      />
    </div>
  )
}

StagesEditorControls.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.pathStage).isRequired,
  onAdd: PropTypes.func.isRequired,
  className: PropTypes.string
}

StagesEditorControls.defaultProps = {
  className: ''
}
