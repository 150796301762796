import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

import paths from '../../api/paths'

import PathForm from './PathForm'

export default function PathBuilder () {
  const navigate = useNavigate()

  const [data, updatePath] = useReducer((state, patch) => ({ ...state, ...patch }), {
    code: '',
    name: '',
    description: '',
    stages: [],
    archived: false
  })

  const saveMutation = useMutation((pathData) => paths.create(pathData), {
    onSuccess (res) {
      navigate(`/paths/edit/${res.data.code}`)
    }
  })

  const { isLoading: isSaving, error: saveError } = saveMutation

  const onSavePath = (pathData) => {
    updatePath(pathData)
    saveMutation.mutate(pathData)
  }

  return (
    <div className='rounded shadow bg-white p-4'>
      <h2 className='me-auto'>New Path</h2>

      <PathForm
        path={data}
        isSaving={isSaving}
        saveError={saveError}
        onSavePath={onSavePath}
      />
    </div>
  )
}
