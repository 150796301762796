import React from 'react'

import { Plus } from 'react-bootstrap-icons'

import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

import paths from '../../api/paths'

import usePagedCollection from '../../hooks/usePagedCollection'

import HeadedPage from '../../components/HeadedPage'
import Paginator from '../../components/Paginator'
import Button from '../../components/Button'
import SearchBar from '../../components/SearchBar'

import PathList from './PathList'
import LinkIconButton from '../../components/LinkIconButton'

export default function PathsPage () {
  const { page, params, isError } = usePagedCollection('path', paths)

  return (
    <HeadedPage
      title='Pathways'
      headerContents={(
        <>
          <div className='d-flex align-items-center'>
            <h1 className='me-auto'>Training Paths</h1>
            <LinkIconButton icon={<Plus size={32} aria-hidden />} variant='primary' className='p-1 pe-3' to='/paths/builder'>Create new path</LinkIconButton>
          </div>
          <div className='my-2 d-flex gap-2 flex-wrap'>
            <Button aria-label='Show All' variant={params.archivedOnly ? 'outline-light' : 'primary'} onClick={() => params.setArchived(false)}>All</Button>
            <Button aria-label='Only Show Archived' variant={params.archivedOnly ? 'primary' : 'outline-light'} onClick={() => params.setArchived(true)}>Archived</Button>
            <SearchBar className='ms-auto flex-grow-1 flex-sm-grow-0' style={{ minWidth: '320px' }} value={params.searchTerm} onSubmit={(value) => params.setSearchTerm(value)} />
          </div>
          <Paginator
            page={page.info}
            links={page.links}
            onSetPage={params.setPage}
            onSetSize={params.setSize}
          />
        </>
      )}
    >
      { isError && <Alert variant='danger'>Failed to load paths. Please try again.</Alert> }

      { page.isLoading && (
        <Alert variant='info'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Spinner size='sm' variant='info' />
            <span>Loading...</span>
          </div>
        </Alert>
      ) }

      { page.isSuccess && (
        page.isEmpty
          ? <Alert variant='warning'>No paths found.</Alert>
          : <PathList paths={page.items} isRefetching={page.isRefetching} />
      ) }

      <Paginator
        page={page.info}
        links={page.links}
        onSetPage={params.setPage}
        onSetSize={params.setSize}
        className='mt-3 pb-5'
      />
    </HeadedPage>
  )
}
