import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { BoxArrowUpRight, Plus, ExclamationOctagonFill } from 'react-bootstrap-icons'

import Spinner from 'react-bootstrap/Spinner'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import IconButton from '../../components/IconButton'

import { useToaster } from '../../components/Toaster'

import cappy from '../../api/cappy'
import modules from '../../api/modules'

import PropTypes from '../../prop-types'

export default function ModuleSuggestions ({ type, after, onAdd, className }) {
  const [addingModule, setAddingModule] = useState(null)
  const toaster = useToaster()

  const msg = type === 'first'
    ? 'Pathways usually start with one of the following modules:'
    : (
        <>
          <span className='text-primary fw-semibold'>{`[${after}] `}</span>
          is often followed by one of these modules:
        </>
      )

  const { isLoading, isSuccess, data, isError, error } = useQuery(
    ['module-suggestions', type, after],
    type === 'first'
      ? () => cappy.modules.suggestFirst()
      : () => cappy.modules.suggestNext(after)
  )

  const onAddSuggestion = (moduleInfo) => {
    const code = moduleInfo.code
    setAddingModule(code)
    modules.get(code)
      .then(data => onAdd(data))
      .catch(error => toaster.push({
        title: 'Error adding module',
        message: error.message,
        variant: 'danger',
        icon: <ExclamationOctagonFill aria-hidden />
      }))
      .then(() => setAddingModule(null))
  }

  return (
    <div className={`p-2 mb-3 bg-light border rounded shadow-sm d-inline-block ${className}`}>
      <div className='p-3 mb-2 bg-white rounded d-inline-block'>
        <h4 className='mb-1 fs-5'>Cappy Suggests:</h4>
        { isLoading && <div className='text-muted fst-italic'>Loading suggestions...</div> }
        { isError && <div className='text-danger'>{ error.message ?? 'Failed to load suggestions.' }</div> }
        { isSuccess && data._embedded === undefined && (
          type === 'first'
            ? <p className='m-0' style={{ maxWidth: '65ch' }}>If you create some training paths and add modules to them manually, I can learn what a good training path looks like and start recommending modules for you!</p>
            : <p className='m-0'>There aren't usually any modules after <span className='text-primary fw-semibold'>[{after}]</span></p>
        ) }
        { isSuccess && data._embedded !== undefined && msg }
      </div>
      { isSuccess && data._embedded !== undefined && (
        <>
          <ul className='list-unstyled d-flex flex-wrap gap-2 m-0'>
            { data._embedded.moduleSuggestionList.map(({ module, score }) => (
              <li key={module.code} className='bg-white p-2 rounded border shadow-sm m-0'>
                <h5 className='rounded-1 p-1 px-2 bg-white fs-5'>
                  <span className='text-muted'>{ `[${module.code}] ` }</span>
                  {module.name}
                </h5>
                <div className='d-flex align-items-center gap-1'>
                  <div className='fst-italic text-secondary fs-5 fw-bold ps-1'>{ Math.round(score * 100) }%</div>
                  <OverlayTrigger
                    delay={250}
                    placement='bottom'
                    overlay={(
                      <Tooltip id='tooltip-module-link'>Go to module</Tooltip>
                    )}
                  >
                    <IconButton aria-label='View module in new tab' icon={<BoxArrowUpRight aria-hidden />} variant='primary' className='ms-auto p-2' href={`/modules/edit/${module.code}`} target='_blank' />
                  </OverlayTrigger>
                  <IconButton
                    variant='green'
                    icon={<Plus size={32} style={{ margin: '-4px' }} aria-hidden />}
                    className='py-1'
                    onClick={() => onAddSuggestion(module)}
                    disabled={addingModule !== null}
                  >
                    {
                      addingModule === module.code
                        ? <Spinner size='sm' aria-label='Adding module...' />
                        : 'Add'
                    }
                  </IconButton>
                </div>
              </li>
            )) }
          </ul>
        </>
      ) }
    </div>
  )
}

ModuleSuggestions.propTypes = {
  type: PropTypes.oneOf(['first', 'next']),
  after: props => {
    if (props.type === 'next' && (props.after === undefined || typeof props.after !== 'string')) {
      return new Error('You must provide an \'after\' prop when value of prop \'type\' is "next".')
    } else if (props.type === 'first' && props.after !== undefined) {
      return new Error('The \'after\' prop is unused when value of prop \'type\' is "first".')
    }
  },
  onAdd: PropTypes.func.isRequired,
  className: PropTypes.string
}

ModuleSuggestions.defaultProps = {
  className: ''
}
