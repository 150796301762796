import React, { useRef, useState, useEffect } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import PropTypes from '../../prop-types'
import newlineAwareSize from '../../util/newlineAwareSize'

export default function PathDetailsEditor ({
  editMode, path, update, formErrors, disabled
}) {
  const { code, name, description } = path
  const descriptionLength = newlineAwareSize(description)

  const codeRef = useRef(null)
  const [codeSelection, setCodeSelection] = useState(null)

  const onChangeCode = ({ target }) => {
    const { value, selectionStart, selectionEnd } = target
    setCodeSelection({ start: selectionStart, end: selectionEnd })
    update({ code: value.toUpperCase() })
  }

  useEffect(() => {
    if (codeSelection === null) return
    const { start, end } = codeSelection
    codeRef.current.setSelectionRange(start, end)
  }, [codeSelection])

  return (
    <div className='mt-2'>
      <Row className='mb-2'>
        <Col md={4} lg={3}>
          <Form.Group>
            <div className='mb-1'>
              <label htmlFor='pathCode'>Path Code</label>
              <span className={`ms-1 ${code.length < 1 || code.length > 10 ? 'text-danger' : 'text-muted'}`}>({code.length}/10)</span>
            </div>
            <Form.Control id='pathCode' ref={codeRef} type='text' disabled={editMode} placeholder='AB-XYZ' value={code} onChange={onChangeCode} />
            <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.code }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mt-2 mt-md-0'>
            <div className='mb-1'>
              <label htmlFor='pathName'>Path Name</label>
              <span className={`ms-1 ${name.length < 2 || name.length > 30 ? 'text-danger' : 'text-muted'}`}>({name.length}/40)</span>
            </div>
            <Form.Control id='pathName' type='text' disabled={disabled} placeholder='Human-facing Training Path Name' value={name} onChange={({ target }) => update({ name: target.value })} />
            <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.name }</Form.Text>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <div className='mb-1'>
          <label htmlFor='pathDescription'>Description</label>
          <span className={`ms-1 ${descriptionLength > 1100 ? 'text-danger' : 'text-muted'}`}>({descriptionLength}/1100)</span>
        </div>
        <Form.Control as='textarea' id='pathDescription' rows={6} disabled={disabled} value={description} onChange={({ target }) => update({ description: target.value })} />
        <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.description }</Form.Text>
      </Form.Group>
    </div>
  )
}

PathDetailsEditor.propTypes = {
  editMode: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  path: PropTypes.path.isRequired,
  formErrors: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  update: PropTypes.func.isRequired
}
