import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'

import { BoxArrowUp, Hourglass, BoxArrowUpRight } from 'react-bootstrap-icons'

import IconButton from './IconButton'
import Button from './Button'

import { ArchivedDependenciesError } from '../api/BluecapError'

export default function RestoreButton ({ onClick, isLoading, isError, error }) {
  const [showModal, setShowModal] = useState(false)

  const hideModal = () => setShowModal(false)

  useEffect(() => {
    setShowModal(isError && error instanceof ArchivedDependenciesError)
  }, [isError, error, setShowModal])

  return (
    <>
      <IconButton variant='outline-red' icon={isLoading ? <Hourglass aria-label='Loading' /> : <BoxArrowUp aria-hidden />} disabled={isLoading} onClick={onClick}>
        Restore
      </IconButton>
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Restoration Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This path cannot be restored because it depends on the following archived modules:</p>
          <ul>
            { error?.modules?.map(
              moduleCode => (
                <li key={moduleCode}>
                  <Link to={`/modules/edit/${moduleCode}`} target='_blank'>
                    [{ moduleCode }]
                    <BoxArrowUpRight className='text-decoration-none ms-2 position-relative' style={{ top: '-3px' }} />
                  </Link>
                </li>
              )
            ) }
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideModal}>Okay</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

RestoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error)
}
