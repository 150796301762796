import api from './api'

export default {
  async get (code) {
    return api.get('qualifications', code)
  },

  async list () {
    return api.list('qualifications', { size: 2000 })
  },

  async create (data) {
    return api.post('qualifications', data)
  },

  async update (code, data) {
    return api.patch('qualifications', code, data)
  },

  async delete (code) {
    return api.delete('qualifications', code)
  }
}
