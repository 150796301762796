import React from 'react'

import PathCard from './PathCard'

import PropTypes from '../../prop-types'

export default function PathList ({ paths, isRefetching }) {
  return (
    <div className='position-relative'>
      { paths.map((path) => (
        <PathCard path={path} key={path.code} />
      )) }
      { /* Overlay to prevent interaction & indicate inactivity during refetching */ }
      { /* Should reduce chance of layout shifts causing misclicks. */ }
      { isRefetching && <div className='position-absolute top-0 left-0 w-100 h-100 rounded bg-light opacity-50' aria-hidden /> }
    </div>
  )
}

PathList.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.path).isRequired,
  isRefetching: PropTypes.bool.isRequired
}
