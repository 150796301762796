import React, {
  createContext, useContext, useState, useMemo
} from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Offcanvas from 'react-bootstrap/Offcanvas'
import Nav from 'react-bootstrap/Nav'
import { useMsal } from '@azure/msal-react'
import { authEnabled } from '../api/auth'

const NavMenuContext = createContext({})

export function useNavMenu () {
  const context = useContext(NavMenuContext)
  return context
}

export function NavMenuProvider ({ children }) {
  const [isShown, setShown] = useState(false)

  const value = useMemo(() => ({
    isShown,
    show: () => setShown(true),
    hide: () => setShown(false)
  }), [isShown])

  return (
    <NavMenuContext.Provider value={value}>
      { children }
    </NavMenuContext.Provider>
  )
}

NavMenuProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default function NavMenu () {
  // eslint-disable-next-line no-unused-vars
  const { instance, accounts, inProgress } = useMsal()
  const { isShown, hide } = useNavMenu()
  const isAuthenticated = accounts.length > 0 || !authEnabled

  return (
    <Offcanvas as='aside' show={isShown} onHide={hide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav defaultActiveKey='/' className='flex-column'>
          <Nav.Link as={Link} to='/'>Home</Nav.Link>
          {isAuthenticated && (
            <>
              <Nav.Link as={Link} to='/paths'>Paths</Nav.Link>
              <Nav.Link as={Link} to='/modules'>Modules</Nav.Link>
              <Nav.Link as={Link} to='/qualifications'>Qualifications</Nav.Link>
              <Nav.Link as={Link} to='/cohorts' disabled>Cohorts</Nav.Link>
              <Nav.Item className='mt-4'>Welcome, {authEnabled ? accounts[0]?.name : 'Unauthenticated User'}</Nav.Item>
              <Nav.Link as={Link} onClick={() => instance.logout()}>Sign out</Nav.Link>
            </>
          )}
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
