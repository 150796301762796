import React, { useState, useReducer, useEffect } from 'react'

import clone from 'clone'
import deepEqual from 'fast-deep-equal'

import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import newlineAwareSize, { NEWLINE_SIZE } from '../../util/newlineAwareSize'

import BluecapError, { ValidationError } from '../../api/BluecapError'

import SaveButton from '../../components/SaveButton'
import ModuleDetailsEditor from './ModuleDetailsEditor'
import ModuleUnitsEditor from './ModuleUnitsEditor'

import PropTypes from '../../prop-types'

export default function ModuleForm ({
  editMode, module, isSaving, saveError, onSaveModule
}) {
  const [canSave, setCanSave] = useState(false)
  const [isSaved, setSaved] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const checkCanSave = state => {
    const errors = validateState(state)
    const hasErrors = Object.values(errors).some(error => error !== undefined)
    const changed = !deepEqual(module, state)
    setCanSave(changed && !hasErrors)
  }

  const validateState = state => {
    const errors = {}

    if (state.code !== undefined) {
      const { code } = state
      if (code.length < 1 || code.length > 10) errors.code = 'Must be 1-10 characters long.'
      else if (/[^A-Z0-9-]/.test(code)) errors.code = 'May contain only A-Z, 0-9, and -'
      else errors.code = undefined
    }

    if (state.name !== undefined) {
      const { name } = state
      if (name.length < 2 || name.length > 30) errors.name = 'Must be 2-30 characters long.'
      else if (/^\s/.test(name)) errors.name = 'May not have leading whitespace.'
      else if (/\s$/.test(name)) errors.name = 'May not have trailing whitespace.'
      else errors.name = undefined
    }

    if (state.description !== undefined) {
      const { description } = state
      const length = newlineAwareSize(description)
      if (length > 1000) errors.description = `Exceeds character count. Maximum 1100 characters, line breaks count as ${NEWLINE_SIZE} characters.`
      else errors.description = undefined
    }

    if (state.length !== undefined) {
      const { length } = state
      if (length < 1) errors.length = 'Must be more than 0.'
      else errors.length = undefined
    }

    return errors
  }

  const [data, update] = useReducer((state, patch) => {
    const newState = { ...state, ...patch }

    checkCanSave(newState)
    setFormErrors({ ...formErrors, ...validateState(patch) })

    return newState
  }, clone(module))

  useEffect(() => {
    update({ ...module })
  }, [module])

  const doSave = () => {
    onSaveModule(data, () => {
      setSaved(true)
      setTimeout(() => setSaved(false), 5000)
      setCanSave(false)
    })
  }

  let errorMessage = 'An unexpected error occurred while saving the path.'
  if (saveError) {
    if (saveError instanceof ValidationError) {
      errorMessage = (
        <ul className='m-0'>
          { saveError.errors.map((error) => (
            <li key={error.codes[0]}>{ `Field "${error.field}" ${error.defaultMessage}` }</li>
          )) }
        </ul>
      )
    } else if (saveError instanceof BluecapError) {
      errorMessage = saveError.message
    }
  }

  return (
    <Form>
      { saveError && <Alert variant='danger mt-3'>{ errorMessage }</Alert> }
      { module.archived && <Alert variant='warning' className='mt-2'>This module is archived. If you with to make changes to the module, it must first be restored.</Alert> }

      <ModuleDetailsEditor update={update} data={data} editMode={editMode} formErrors={formErrors} />

      { !module.archived && (
        <SaveButton
          isSaved={isSaved}
          isSaving={isSaving}
          editMode={editMode}
          disabled={!canSave || isSaving}
          onClick={doSave}
          className='mt-3'
        />
      ) }

      <ModuleUnitsEditor update={update} data={data} isSaving={isSaving} />
    </Form>
  )
}

ModuleForm.propTypes = {
  editMode: PropTypes.bool,
  module: PropTypes.module.isRequired,
  isSaving: PropTypes.bool.isRequired,
  saveError: PropTypes.object,
  onSaveModule: PropTypes.func.isRequired
}

ModuleForm.defaultProps = {
  editMode: false,
  saveError: null
}
