import React, { useReducer, useEffect } from 'react'

import clone from 'clone'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import {
  ChevronUp, ChevronDown, XLg, PlusLg
} from 'react-bootstrap-icons'

import IconButton from '../../components/IconButton'

import PropTypes from '../../prop-types'

export default function ModuleUnitsEditor ({ data, update }) {
  const [units, dispatch] = useReducer((state, mutation) => {
    const [action, payload] = mutation
    const nextUnits = clone(state)
    if (action === 'move-up') {
      const idx = payload
      const [unit] = nextUnits.splice(idx, 1)
      nextUnits.splice(idx - 1, 0, unit)
    } else if (action === 'move-down') {
      const idx = payload
      const [unit] = nextUnits.splice(idx, 1)
      nextUnits.splice(idx + 1, 0, unit)
    } else if (action === 'rename') {
      const [idx, nextName] = payload
      const unit = nextUnits[idx]
      unit.name = nextName
    } else if (action === 'create') {
      const idx = payload
      nextUnits.splice(idx + 1, 0, { position: idx + 1, name: '' })
    } else if (action === 'delete') {
      const idx = payload
      nextUnits.splice(idx, 1)
    } else if (action === 'nameNew') {
      const name = payload
      nextUnits.push({ name, position: 0 })
    } else if (action === 'update') {
      return payload ?? []
    }
    return nextUnits
      .map((unit, i) => ({ ...unit, position: i }))
  }, data.units ?? [])

  useEffect(() => {
    update({ units })
  }, [update, units])

  useEffect(() => {
    dispatch(['update', data.units])
  }, [data])

  return (
    <section className='mt-4'>
      <h3>Units</h3>
      <div className='d-flex flex-column gap-2'>
        { units.map((unit) => (
          <InputGroup key={unit.position}>
            <InputGroup.Text className='d-flex flex-column p-0 overflow-hidden'>
              <IconButton variant='light' className='p-1 rounded-0' aria-label='Move up' icon={<ChevronUp aria-hidden />} onClick={() => dispatch(['move-up', unit.position])} />
              <IconButton variant='light' className='p-1 rounded-0' aria-label='Move down' icon={<ChevronDown aria-hidden />} onClick={() => dispatch(['move-down', unit.position])} />
            </InputGroup.Text>
            <Form.Control type='text' value={unit.name} placeholder='Unit name' onChange={({ target }) => dispatch(['rename', [unit.position, target.value]])} />
            <IconButton variant='primary' aria-label='Add another unit' icon={<PlusLg aria-hidden size={24} />} onClick={() => dispatch(['create', unit.position])} />
            <IconButton variant='outline-danger' aria-label='Remove this unit' icon={<XLg aria-hidden size={20} />} onClick={() => dispatch(['delete', unit.position])} />
          </InputGroup>
        )) }
        { units.length === 0 && (
          <IconButton icon={<PlusLg aria-hidden />} onClick={() => dispatch(['create', 0])}>Add Unit</IconButton>
        ) }
      </div>
    </section>
  )
}

ModuleUnitsEditor.propTypes = {
  update: PropTypes.func.isRequired,
  data: PropTypes.module.isRequired
}
