import React from 'react'

import { useNavigate } from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'

import { ArchiveFill } from 'react-bootstrap-icons'

import useModuleArchiving from '../../hooks/useModuleArchiving'

import EditButton from '../../components/EditButton'
import ArchiveOrRestoreButton from '../../components/ArchiveOrRestoreButton'

import PropTypes from '../../prop-types'
import QualificationBadge from '../../components/QualificationBadge'

export default function ModuleCard ({ module }) {
  const navigate = useNavigate()

  const moduleArchivingProps = useModuleArchiving(module.code)

  return (
    <div className='rounded bg-white p-2 mb-2 shadow-sm'>
      <Row className='g-2'>
        <Col md={2} className='d-none d-md-block'>
          <img
            src={`https://picsum.photos/seed/${module.code}/250`}
            alt='Module Thumbnail'
            className='d-none d-lg-block rounded w-100'
            style={{ filter: 'sepia(100%) hue-rotate(160deg) saturate(120%) brightness(90%) contrast(120%)' }}
          />
          <img
            src={`https://picsum.photos/seed/${module.code}/200/280`}
            alt='Module Thumbnail'
            className='d-block d-lg-none rounded w-100'
            style={{ filter: 'sepia(100%) hue-rotate(170deg) saturate(120%) brightness(90%) contrast(120%)' }}
          />
        </Col>
        <Col md={8}>
          <h3 className='rounded-1 bg-light p-1 px-2'>
            { module.archived && <ArchiveFill color='var(--bs-yellow)' size='0.8em' className='align-middle me-2' aria-label='Archived Module' /> }
            <span className='text-muted'>{ `[${module.code}] ` }</span>
            { module.name }
            { module.qualification && (
              <QualificationBadge qualification={module.qualification} size={24} />
            ) }
          </h3>
          <p className='m-0 px-2'>
            { module.description }
          </p>
          <p className='m-0 mt-2 px-2'>
            <span className='fw-semibold'>Length:</span>
            { ' ' }
            { module.length }
            { ' ' }
            days
          </p>
          <p className='m-0 mt-2 mb-2 px-2'>
            <span className='fw-semibold'>Includes:</span>
            { ' ' }
            { module.units.map((unit) => unit.name).join(', ') }
          </p>
        </Col>
        <Col md={2}>
          <Stack gap={1}>
            <EditButton onClick={() => navigate(`/modules/edit/${module.code}`)} />
            <ArchiveOrRestoreButton isArchived={module.archived} {...moduleArchivingProps} />
          </Stack>
        </Col>
      </Row>
    </div>
  )
}

ModuleCard.propTypes = {
  module: PropTypes.module.isRequired
}
