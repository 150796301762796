import React, { useReducer } from 'react'
import { useMutation } from 'react-query'

import modules from '../../api/modules'

import ModuleForm from './ModuleForm'

import PropTypes from '../../prop-types'

export default function ModuleBuilder ({ onSave }) {
  const [data, updateModule] = useReducer((state, patch) => ({ ...state, ...patch }), {
    code: '',
    name: '',
    description: '',
    length: 1,
    archived: false,
    units: []
  })

  const saveMutation = useMutation((moduleData) => modules.create(moduleData), {
    onSuccess (res) {
      onSave(res)
    }
  })

  const { isLoading: isSaving, error: saveError } = saveMutation

  const onSaveModule = (moduleData) => {
    updateModule(moduleData)
    saveMutation.mutate(moduleData)
  }

  return (
    <div className='rounded shadow bg-white p-4'>
      <h2 className='me-auto'>New Module</h2>

      <ModuleForm
        module={data}
        isSaving={isSaving}
        saveError={saveError}
        onSaveModule={onSaveModule}
      />
    </div>
  )
}

ModuleBuilder.propTypes = {
  onSave: PropTypes.func.isRequired
}
