import React from 'react'

import { useNavigate } from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'

import { ArchiveFill } from 'react-bootstrap-icons'

import PathCardModuleList from './PathCardModuleList'

import EditButton from '../../components/EditButton'
import PowerPointButton from '../../components/PowerPointButton'
import ArchiveOrRestoreButton from '../../components/ArchiveOrRestoreButton'

import usePathArchiving from '../../hooks/usePathArchiving'

import PropTypes from '../../prop-types'

export default function PathCard ({ path }) {
  const navigate = useNavigate()

  const pathArchivingProps = usePathArchiving(path.code)

  return (
    <div className='rounded bg-white p-2 mb-2 shadow-sm'>
      <Row className='g-2 mb-4 mb-md-0'>
        <Col md={2} className='d-none d-md-block'>
          <img
            src={`https://picsum.photos/seed/${path.code}/250`}
            alt='Path Thumbnail'
            className='d-none d-lg-block rounded w-100'
            style={{ filter: 'sepia(100%) hue-rotate(170deg) saturate(120%) brightness(90%) contrast(120%)' }}
          />
          <img
            src={`https://picsum.photos/seed/${path.code}/200/280`}
            alt='Path Thumbnail'
            className='d-block d-lg-none rounded w-100'
            style={{ filter: 'sepia(100%) hue-rotate(170deg) saturate(120%) brightness(90%) contrast(120%)' }}
          />
        </Col>
        <Col md={8}>
          <h3 className='rounded-1 bg-light p-1 px-2'>
            { path.archived && <ArchiveFill color='var(--bs-yellow)' size='0.8em' className='align-middle me-2' aria-label='Archived Pathway' /> }
            <span className='text-muted'>{ `[${path.code}]` }</span>
            { ' ' }
            { path.name }
          </h3>
          <p className='m-0 px-2'>
            { path.description }
          </p>
          <div className='text-muted mb-n2 px-2 mt-1'>
            <PathCardModuleList stages={path.stages} />
          </div>
        </Col>
        <Col md={2}>
          <Stack gap={1}>
            <EditButton onClick={() => navigate(`/paths/edit/${path.code}`)} />
            <PowerPointButton code={path.code} numModules={path.stages.length} />
            <ArchiveOrRestoreButton isArchived={path.archived} {...pathArchivingProps} />
          </Stack>
        </Col>
      </Row>
    </div>
  )
}

PathCard.propTypes = {
  path: PropTypes.path.isRequired
}
