import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'

const IconButton = forwardRef(({ icon, children, ...props }, ref) => (
  <Button ref={ref} {...props}>
    { icon }
    { children && <span>{ children }</span> }
  </Button>
))

IconButton.displayName = 'IconButton'

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
}

IconButton.defaultProps = {
  className: '',
  children: undefined
}

export default IconButton
