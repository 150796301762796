import React from 'react'
import PropTypes from 'prop-types'

export default function AutoTruncated ({
  as, breakpoint, display, className, children, ...props
}) {
  return (
    <>
      { React.createElement(as, {
        className: `d-${breakpoint}-none text-truncate ${className}`,
        ...props
      }, ...children) }
      { React.createElement(as, {
        className: `d-none d-${breakpoint}-${display} ${className}`,
        ...props
      }, ...children) }
    </>
  )
}

AutoTruncated.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  breakpoint: PropTypes.string,
  display: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

AutoTruncated.defaultProps = {
  as: 'div',
  breakpoint: 'sm',
  display: 'block',
  className: '',
  children: undefined
}
