import React, { useState, useReducer } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useMutation } from 'react-query'

import deepEqual from 'fast-deep-equal'

import Breadcrumb from 'react-bootstrap/Breadcrumb'

import HeadedPage from '../../components/HeadedPage'

import qualifications from '../../api/qualifications'

import QualificationForm from './QualificationForm'

const defaultData = {
  typeName: 'EXAM',
  code: '',
  name: '',
  description: '',
  unitPrice: '0',
  unitPriceCurrency: 'GBP',
  canBuyInBulk: false,
  hasLink: false,
  link: ''
}

export default function QualificationBuilderPage () {
  const navigate = useNavigate()

  const [isChanged, setChanged] = useState(false)
  const [isSaved, setSaved] = useState(false)

  const [data, update] = useReducer((state, patch) => {
    const nextState = { ...state, ...patch }
    setChanged(!deepEqual(defaultData, nextState))
    return nextState
  }, { ...defaultData })

  const saveMutation = useMutation((qualificationData) => qualifications.create(qualificationData), {
    onSuccess (res) {
      navigate(`/qualifications/edit/${res.data.code}`)
    }
  })

  const { isLoading: isSaving, error: saveError } = saveMutation

  const onSave = () => {
    const { code, typeName, name, description, canBuyInBulk, unitPriceCurrency } = data
    const link = data.hasLink ? data.link : null

    const unitPriceNormalised = Math.trunc(parseFloat(data.unitPrice, 10) * 100)

    const qualificationData = { code, typeName, name, description, canBuyInBulk, link, unitPrice: unitPriceNormalised, unitPriceCurrency }

    saveMutation
      .mutateAsync(qualificationData)
      .then(() => {
        setSaved(true)
        setTimeout(() => setSaved(false), 5000)
        setChanged(false)
      })
  }

  const vars = {
    '--bs-link-color': 'white',
    '--bs-link-hover-color': '#c882bd',
    '--bs-breadcrumb-item-active-color': 'white'
  }

  return (
    <HeadedPage
      headerContents={(
        <>
          <h1>Qualification Builder</h1>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/qualifications' }} style={vars}>Qualifications</Breadcrumb.Item>
            <Breadcrumb.Item active style={vars}>Builder</Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
    >
      <div className='rounded shadow bg-white p-4'>
        <QualificationForm
          formData={data}
          update={update}
          isChanged={isChanged}
          onSave={onSave}
          isSaving={isSaving}
          isSaved={isSaved}
          saveError={saveError}
        />
      </div>
    </HeadedPage>
  )
}
