import { useEffect } from 'react'

export default function useTitle (title) {
  useEffect(() => {
    if (title === undefined) return () => {}

    const oldTitle = document.title
    document.title = `${title} | ${oldTitle}`

    return () => { document.title = oldTitle }
  }, [title])
}
