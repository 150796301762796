import { useQueryClient, useMutation } from 'react-query'

import paths from '../api/paths'

export default function usePathArchiving (pathCode, options) {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(['paths'])
    queryClient.invalidateQueries(['path', pathCode])
    options?.onSuccess?.()
  }

  const { mutate: onArchive, isLoading: isArchiving, isError: isArchiveError, error: archiveError } = useMutation(
    ['path', pathCode],
    () => paths.update(pathCode, { archived: true }),
    { onSuccess }
  )

  const { mutate: onRestore, isLoading: isRestoring, isError: isRestoreError, error: restoreError } = useMutation(
    ['path', pathCode],
    () => paths.update(pathCode, { archived: false }),
    { onSuccess }
  )

  return {
    isArchiving,
    isRestoring,
    isError: isArchiveError || isRestoreError,
    error: archiveError ?? restoreError,
    onArchive,
    onRestore
  }
}
