import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { TrashFill } from 'react-bootstrap-icons'

import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import IconButton from '../../components/IconButton'
import Button from '../../components/Button'

import qualifications from '../../api/qualifications'

import PropTypes from '../../prop-types'

export default function QualificationDangerZone ({ code }) {
  const navigate = useNavigate()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const deleteMutation = useMutation(() => qualifications.delete(code), {
    onSuccess () {
      navigate('/qualifications')
    },
    onError () {
      setShowDeleteModal(false)
    }
  })

  const onDelete = () => {
    deleteMutation.mutate()
  }

  return (
    <div className='bg-light p-2 rounded border shadow-sm mt-3'>
      <h2 className='rounded-1 p-2 bg-white text-danger fs-4'>Danger Zone</h2>
      {deleteMutation.isError && <Alert variant='danger'>{ deleteMutation.error.message }</Alert>}
      <IconButton icon={<TrashFill aria-hidden />} variant='danger' onClick={() => setShowDeleteModal(true)}>Delete Qualification</IconButton>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this qualification?</p>
          <p className='text-danger'>This operation cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant='danger' onClick={onDelete} disabled={deleteMutation.isLoading}>
            {
              deleteMutation.isLoading
                ? <Spinner size='sm' className='my-1 mx-3' aria-label='Deleting qualification' />
                : `Delete [${code}]`
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

QualificationDangerZone.propTypes = {
  code: PropTypes.string.isRequired
}
