import PropTypes from 'prop-types'
import price from './price'

export default PropTypes.shape({
  code: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['EXAM', 'COURSE']).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string,
  unitPrice: price.isRequired,
  canBuyInBulk: PropTypes.bool.isRequired
})
