import PropTypes from 'prop-types'

import qualification from './qualification'
import unit from './unit'

export default PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  units: PropTypes.arrayOf(unit).isRequired,
  qualification,
  archived: PropTypes.bool.isRequired
})
