import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import HomePage from './pages/home/HomePage'

import PathsPage from './pages/paths/PathsPage'
import PathBuilderPage from './pages/path-builder/PathBuilderPage'
import PathEditorPage from './pages/path-builder/PathEditorPage'

import ModulesPage from './pages/modules/ModulesPage'
import ModuleBuilderPage from './pages/module-builder/ModuleBuilderPage'
import ModuleEditorPage from './pages/module-builder/ModuleEditorPage'

import QualificationsPage from './pages/qualifications/QualificationsPage'
import QualificationBuilderPage from './pages/qualification-builder/QualificationBuilderPage'
import QualificationEditorPage from './pages/qualification-builder/QualificationEditorPage'

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: 'paths',
        children: [
          {
            index: true,
            element: <PathsPage />
          },
          {
            path: 'builder',
            element: <PathBuilderPage />
          },
          {
            path: 'edit/:code',
            element: <PathEditorPage />
          }
        ]
      },
      {
        path: 'modules',
        children: [
          {
            index: true,
            element: <ModulesPage />
          },
          {
            path: 'builder',
            element: <ModuleBuilderPage />
          },
          {
            path: 'edit/:code',
            element: <ModuleEditorPage />
          }
        ]
      },
      {
        path: 'qualifications',
        children: [
          {
            index: true,
            element: <QualificationsPage />
          },
          {
            path: 'builder',
            element: <QualificationBuilderPage />
          },
          {
            path: 'edit/:code',
            element: <QualificationEditorPage />
          }
        ]
      }
    ]
  }
])

export default router
