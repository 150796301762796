import React from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Tooltip } from 'react-bootstrap'

import { AwardFill } from 'react-bootstrap-icons'

import PropTypes from '../prop-types'

export default function QualificationBadge ({ qualification, size }) {
  const label = `Includes: ${qualification.name}`

  return (
    <OverlayTrigger
      delay={250}
      overlay={(
        <Tooltip id='tooltip-module-link'>{ label }</Tooltip>
      )}
    >
      <AwardFill color='var(--bs-primary)' size={size} className='position-relative ms-1' style={{ top: '-3px' }} aria-label={label} />
    </OverlayTrigger>
  )
}

QualificationBadge.propTypes = {
  qualification: PropTypes.qualification.isRequired,
  size: PropTypes.number
}

QualificationBadge.defaultProps = {
  size: undefined
}
