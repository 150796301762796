import React from 'react'
import PropTypes from 'prop-types'

import Container from 'react-bootstrap/Container'
import Page from './Page'

export default function HeadedPage ({ title, headerContents, children, requiresAuth = true }) {
  return (
    <Page title={title} requiresAuth={requiresAuth}>
      <header className='bg-dark text-light py-4 px-sm-4 shadow'>
        <Container style={{ paddingBottom: '8rem' }}>
          { headerContents }
        </Container>
      </header>
      <section style={{ marginTop: '-8rem' }}>
        <Container>
          { children }
        </Container>
      </section>
    </Page>
  )
}

HeadedPage.propTypes = {
  title: PropTypes.string,
  headerContents: PropTypes.node,
  children: PropTypes.node,
  requiresAuth: PropTypes.bool
}

HeadedPage.defaultProps = {
  title: undefined,
  headerContents: undefined,
  children: undefined,
  requiresAuth: true
}
