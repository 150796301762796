import React, { useState } from 'react'

import Form from 'react-bootstrap/Form'
import { useQuery } from 'react-query'
import qualifications from '../../api/qualifications'
import PropTypes from '../../prop-types'

export default function QualificationSelector ({
  disabled, value, onChange, ...props
}) {
  const [selectedQualification, setSelected] = useState(value)

  const {
    isLoading, isError, isSuccess, data
  } = useQuery(['qualifications'], () => qualifications.list())

  const qualificationList = data?._embedded?.qualificationList ?? []

  const doChange = ({ target }) => {
    const nextCode = target.value
    const nextQualification = qualificationList.find(
      (qualification) => qualification.code === nextCode
    )
    setSelected(nextQualification)
    onChange(nextQualification)
  }

  return (
    <Form.Group {...props}>
      <Form.Label className='mb-1' htmlFor='moduleQualification'>Qualification</Form.Label>
      <Form.Select id='moduleQualification' disabled={disabled || isLoading || isError} value={selectedQualification?.code} onChange={doChange} className='w-auto mw-100'>
        { isLoading && <option>Loading Qualifications...</option> }
        { isError && <option>Failed to load.</option> }
        { isSuccess && (
          <>
            <option>No Qualification</option>
            { qualificationList.map((qualification) => (
              <option
                key={qualification.code}
                value={qualification.code}
              >
                { `[${qualification.code}] ${qualification.name}` }
              </option>
            )) }
          </>
        ) }
      </Form.Select>
    </Form.Group>
  )
}

QualificationSelector.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.qualification,
  onChange: PropTypes.func.isRequired
}

QualificationSelector.defaultProps = {
  disabled: false,
  value: null
}
