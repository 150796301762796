import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export default function usePagedCollection (name, resourceAPI) {
  const [params, setParams] = useSearchParams()

  // Get URL query parameters
  const archivedOnly = params.get('archived') === 'true'
  const searchTerm = params.get('q') ?? undefined
  const page = parseInt(params.get('page') ?? 1, 10)
  const size = parseInt(params.get('size') ?? 50, 0)

  // Update query parameters by applying a partial patch
  function patchParams (patch) {
    const nextParams = {}

    const nextArchived = patch.archived ?? archivedOnly ?? false
    if (nextArchived) nextParams.archived = true

    const nextSearchTerm = patch.searchTerm ?? searchTerm ?? ''
    if (nextSearchTerm !== '') nextParams.q = nextSearchTerm

    const nextPage = patch.page ?? page
    if (nextPage !== undefined) nextParams.page = nextPage

    const nextSize = patch.size ?? size
    if (nextSize !== undefined) nextParams.size = nextSize

    setParams(nextParams, {
      replace: true,
      state: {}
    })
  }

  function setPage (nextPage) {
    patchParams({ page: nextPage })
  }

  function setSize (nextSize) {
    patchParams({ size: nextSize, page: 1 })
  }

  function setArchived (nextArchived) {
    patchParams({ archived: nextArchived, page: 1 })
  }

  function setSearchTerm (nextSearchTerm) {
    patchParams({ searchTerm: nextSearchTerm })
  }

  // Fetch a page of documents
  const {
    isLoading, isSuccess, isError, data, isRefetching
  } = useQuery([name, archivedOnly, searchTerm, page, size], () => resourceAPI.list(archivedOnly, searchTerm, page, size))

  // Extract the list of documents (if present)
  const items = data?._embedded?.[`${name}List`]

  return {
    isError,
    page: {
      isLoading,
      isSuccess,
      isRefetching,
      isEmpty: items === undefined || items.length === 0,
      items,
      info: data?.page,
      links: data?._links
    },
    params: {
      page,
      archivedOnly,
      searchTerm,
      setPage,
      setSize,
      setArchived,
      setSearchTerm
    }
  }
}
