import React from 'react'
import PropTypes from 'prop-types'

import { Hourglass, CheckLg } from 'react-bootstrap-icons'

import IconButton from './IconButton'

export default function SaveButton ({
  editMode, disabled, isSaved, isSaving, onClick, className
}) {
  return (
    <div className={`d-flex justify-content-end align-items-center gap-2 ${className}`}>
      { isSaved && <div className='text-success' role='alert'>All changes saved.</div> }
      <IconButton variant={disabled ? 'outline-green' : 'green'} icon={isSaving ? <Hourglass aria-label='Saving' /> : <CheckLg aria-hidden />} disabled={disabled} onClick={onClick}>
        { editMode ? 'Save changes' : 'Create' }
      </IconButton>
    </div>
  )
}

SaveButton.propTypes = {
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  isSaved: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

SaveButton.defaultProps = {
  editMode: false,
  disabled: false,
  className: ''
}
