import api from './api'

export default {
  async get (code) {
    return api.get('modules', code)
  },

  async list (archivedOnly, searchTerm, page, size) {
    const archivedParam = archivedOnly === true ? { archived: true } : {}
    const searchParam = searchTerm !== undefined ? { search: searchTerm } : {}
    const pageParam = page !== undefined ? { page: page - 1 } : {}
    const sizeParam = size !== undefined ? { size } : {}

    const params = new URLSearchParams({
      ...searchParam,
      ...pageParam,
      ...sizeParam,
      ...archivedParam
    })

    return api.list('modules', params)
  },

  async create (moduleData) {
    const payload = {
      code: moduleData.code,
      name: moduleData.name,
      description: moduleData.description,
      length: moduleData.length,
      qualification: moduleData.qualification?.code,
      units: moduleData.units?.filter((unit) => unit.name.length > 0)
    }

    return api.post('modules', payload)
  },

  async update (code, moduleData) {
    const payload = {
      name: moduleData.name,
      description: moduleData.description,
      archived: moduleData.archived,
      length: moduleData.length,
      qualification: moduleData.qualification?.code,
      units: moduleData.units?.filter((unit) => unit.name.length > 0)
    }

    return api.patch('modules', code, payload)
  }
}
