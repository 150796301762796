import React from 'react'

import Modal from 'react-bootstrap/Modal'

import ModuleBuilder from '../module-builder/ModuleBuilder'

import PropTypes from '../../prop-types'

export default function NewModuleModal ({ show, onClose, onAdd }) {
  const onSave = (res) => {
    onAdd(res.data)
    onClose()
  }

  return (
    <Modal size='lg' show={show} onHide={onClose}>
      <Modal.Body>
        <ModuleBuilder onSave={onSave} />
      </Modal.Body>
    </Modal>
  )
}

NewModuleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
}
