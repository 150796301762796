import api from './api'

export default {
  async get (code) {
    return api.get('paths', code)
  },

  async list (archivedOnly, searchTerm, page, size) {
    const archivedParam = archivedOnly === true ? { archived: true } : {}
    const searchParam = searchTerm !== undefined ? { search: searchTerm } : {}
    const pageParam = page !== undefined ? { page: page - 1 } : {}
    const sizeParam = size !== undefined ? { size } : {}

    const params = new URLSearchParams({
      ...searchParam,
      ...pageParam,
      ...sizeParam,
      ...archivedParam
    })

    return api.list('paths', params)
  },

  async create (pathData) {
    const payload = {
      code: pathData.code,
      name: pathData.name,
      description: pathData.description,
      stages: pathData.stages.map((stage) => ({
        position: stage.position,
        moduleCode: stage.module.code
      }))
    }

    return api.post('paths', payload)
  },

  async update (code, pathData) {
    const payload = {
      name: pathData.name,
      description: pathData.description,
      stages: pathData.stages?.map((stage) => ({
        position: stage.position,
        moduleCode: stage.module.code
      })),
      archived: pathData.archived
    }

    return api.patch('paths', code, payload)
  },

  async downloadPresentation (code) {
    const http = await api.http()
    const { status, data } = await http.get(`paths/${code}/presentation.pptx`, {
      responseType: 'blob',
      timeout: 30000
    })
    if (status !== 200) {
      throw new Error('Unable to download presentation!')
    }
    const url = URL.createObjectURL(data)
    // Janky way to trigger download...
    const a = document.createElement('a')
    a.href = url
    a.download = `${code}.pptx`
    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
  }
}
