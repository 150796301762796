import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, Spinner } from 'react-bootstrap'
import { FileEarmarkArrowDown } from 'react-bootstrap-icons'

import IconButton from './IconButton'
import paths from '../api/paths'

const MAX_MODULES = 8

export default function PowerPointButton ({ code, numModules }) {
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const onClick = async (ev) => {
    ev.preventDefault()
    // If there are sufficiently few modules, or this is coming from the warning modal.
    if (numModules <= MAX_MODULES || showWarningModal) {
      // Hide modal and redirect
      setShowWarningModal(false)
      setDownloading(true)
      await paths.downloadPresentation(code)
      setDownloading(false)
      return
    }
    // Warn first
    setShowWarningModal(true)
  }

  const closeModal = () => setShowWarningModal(false)

  return (
    <>
      <Modal
        show={showWarningModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>PowerPoint Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This pathway has&nbsp;
          { numModules }
          &nbsp;modules. The generated presentation will not display these properly. You can:
          <ul>
            <li>Generate Anyway and edit the slides manually.</li>
            <li>
              Cancel and reduce the number of modules to be no more than&nbsp;
              { MAX_MODULES }
              .
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>Cancel</Button>
          <Button variant='primary' onClick={onClick}>Generate Anyway</Button>
        </Modal.Footer>
      </Modal>
      <IconButton variant='primary' icon={<FileEarmarkArrowDown aria-hidden />} aria-label='Generate PowerPoint' onClick={onClick} target='_blank'>
        {downloading ? <Spinner variant='light' size='sm' aria-label='Downloading...' /> : <>.pptx</>}
      </IconButton>
    </>
  )
}

PowerPointButton.propTypes = {
  code: PropTypes.string.isRequired,
  numModules: PropTypes.number.isRequired
}
