import React from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import { ArchiveFill } from 'react-bootstrap-icons'

import useModuleArchiving from '../../hooks/useModuleArchiving'

import ArchiveOrRestoreButton from '../../components/ArchiveOrRestoreButton'

import ModuleForm from './ModuleForm'

import modules from '../../api/modules'

import PropTypes from '../../prop-types'

export default function ModuleEditor ({ code }) {
  const queryClient = useQueryClient()

  const moduleArchivingProps = useModuleArchiving(code)

  const {
    isLoading, isError, isRefetching, data: module
  } = useQuery(['module', code], () => modules.get(code))

  const saveMutation = useMutation((moduleData) => modules.update(code, moduleData), {
    onSuccess () {
      queryClient.invalidateQueries(['module', code])
    }
  })

  const { isLoading: isSaving, error: saveError } = saveMutation

  const onSaveModule = (moduleData, callback) => {
    saveMutation.mutateAsync(moduleData).then(callback)
  }

  return (
    <div className='rounded shadow bg-white p-4 position-relative'>

      { isLoading && (
        <div className='text-center'>
          <Spinner variant='secondary' size='sm' aria-label='Loading' />
        </div>
      ) }

      { isError && <Alert variant='danger'>Failed to load content.</Alert> }

      { !isLoading && (
        module?.name === undefined
          ? <Alert variant='warning'>No content found.</Alert>
          : <>
              <div className='d-flex align-items-center'>
                { module.archived && <ArchiveFill color='var(--bs-yellow)' size='2em' className='me-2 pb-1' aria-label='Archived Module' /> }
                <h2 className='me-auto'>
                  <span className='text-muted'>{ `[${module.code}] ` }</span>
                  { module.name }
                </h2>
                <div className='d-flex gap-1 flex-column flex-md-row gap-md-2'>
                  <ArchiveOrRestoreButton isArchived={module.archived} {...moduleArchivingProps} />
                </div>
              </div>

              <ModuleForm
                editMode
                module={module}
                isSaving={isSaving}
                saveError={saveError}
                onSaveModule={onSaveModule}
              />
            </>
      ) }

      { /* Overlay to prevent interaction & indicate inactivity during refetching */ }
      { /* Should reduce chance of layout shifts causing misclicks. */ }
      { isRefetching && <div className='position-absolute top-0 w-100 h-100 rounded bg-light opacity-50' style={{ left: '0' }} aria-hidden /> }
    </div>
  )
}

ModuleEditor.propTypes = {
  code: PropTypes.string.isRequired
}
