import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'

import { BoxArrowUpRight } from 'react-bootstrap-icons'

import EditButton from '../../components/EditButton'

import PropTypes from '../../prop-types'
import { useNavigate } from 'react-router-dom'
import MonetaryAmount from '../../components/MonetaryAmount'

export default function QualificationCard ({ qualification }) {
  const navigate = useNavigate()

  return (
    <div className='rounded bg-white p-2 mb-2 shadow-sm'>
      <Row className='g-2 mb-1 mb-md-0'>
        <Col md={10}>
          <h3 className='rounded-1 bg-light p-1 px-2 text-truncate'>
            <span className='text-muted'>{ `[${qualification.code}]` }</span>
            { ' ' }
            { qualification.name }
          </h3>
          <p className='m-0 px-2'>
            { qualification.description || <span className='text-muted fst-italic'>No description provided.</span> }
          </p>
          <Row className='mt-0 px-2 gy-1'>
            <Col md={3} lg={2}>
              <span className='fw-semibold'>Type:</span>
              { ' ' }
              { qualification.type }
            </Col>
            <Col md={6} lg={4}>
              <span className='fw-semibold'>Unit Price:</span>
              { ' ' }
              <MonetaryAmount amount={qualification.unitPrice} />
            </Col>
            <Col>
              <span className='fw-semibold'>Can buy in bulk:</span>
              { ' ' }
              { qualification.canBuyInBulk ? 'Yes' : 'No' }
            </Col>
          </Row>
          <div className='px-2 mt-1 text-truncate'>
            { qualification.link && (
              <a href={qualification.link} target='_blank' rel='noreferrer'>
                <BoxArrowUpRight className='me-1 position-relative' style={{ top: '-2px' }} aria-hidden />
                { qualification.link }
              </a>
            ) }
          </div>
        </Col>
        <Col md>
          <Stack gap={1}>
            <EditButton onClick={() => navigate(`/qualifications/edit/${qualification.code}`)} />
          </Stack>
        </Col>
      </Row>
    </div>
  )
}

QualificationCard.propTypes = {
  qualification: PropTypes.qualification
}
