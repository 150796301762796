import React from 'react'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import { ArchiveFill } from 'react-bootstrap-icons'

import usePathArchiving from '../../hooks/usePathArchiving'

import PowerPointButton from '../../components/PowerPointButton'
import ArchiveOrRestoreButton from '../../components/ArchiveOrRestoreButton'

import PathForm from './PathForm'

import paths from '../../api/paths'

import PropTypes from '../../prop-types'

export default function PathEditor ({ code }) {
  const queryClient = useQueryClient()

  const pathArchivingProps = usePathArchiving(code)

  const {
    isLoading, isError, isRefetching, data: path
  } = useQuery(['path', code], () => paths.get(code))

  const saveMutation = useMutation((pathData) => paths.update(code, pathData), {
    onSuccess () {
      queryClient.invalidateQueries(['path', code])
    }
  })

  const { isLoading: isSaving, error: saveError } = saveMutation

  const onSavePath = (pathData, callback) => {
    saveMutation.mutateAsync(pathData).then(callback)
  }

  return (
    <div className='rounded shadow bg-white p-4 position-relative'>

      { isLoading && (
        <div className='text-center'>
          <Spinner variant='secondary' size='sm' aria-label='Loading' />
        </div>
      ) }

      { isError && <Alert variant='danger'>Failed to load content.</Alert> }

      { !isLoading && (
        path?.name === undefined
          ? <Alert variant='warning'>No content found.</Alert>
          : <>
              <div className='d-flex align-items-center'>
                { path.archived && <ArchiveFill color='var(--bs-yellow)' size='2em' className='me-2 pb-1' aria-label='Archived Pathway' /> }
                <h2 className='me-auto'>
                  <span className='text-muted'>{ `[${path.code}] ` }</span>
                  { path.name }
                </h2>
                <div className='d-flex gap-1 flex-column flex-md-row gap-md-2'>
                  <PowerPointButton code={code} numModules={path.stages.length} />
                  <ArchiveOrRestoreButton isArchived={path.archived} {...pathArchivingProps} />
                </div>
              </div>

              <PathForm
                editMode
                path={path}
                isSaving={isSaving}
                saveError={saveError}
                onSavePath={onSavePath}
              />
            </>
      ) }

      { /* Overlay to prevent interaction & indicate inactivity during refetching */ }
      { /* Should reduce chance of layout shifts causing misclicks. */ }
      { isRefetching && <div className='position-absolute top-0 w-100 h-100 rounded bg-light opacity-50' style={{ left: '0' }} aria-hidden /> }
    </div>
  )
}

PathEditor.propTypes = {
  code: PropTypes.string.isRequired
}
