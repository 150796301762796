import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { ArrowRight } from 'react-bootstrap-icons'

import IconButton from '../../components/IconButton'

export default function ActionCard ({
  icon, name, href, disabled, children
}) {
  const linkStyle = {
    flex: '1 1 0',
    textDecoration: 'none',
    color: 'inherit',
    pointerEvents: disabled ? 'none' : 'auto',
    userSelect: disabled ? 'none' : 'auto',
    filter: disabled ? 'contrast(30%) brightness(150%)' : undefined
  }

  return (
    <Link to={href} style={linkStyle} aria-label={`Go to ${name}`}>
      <article className='rounded shadow-sm bg-white p-2 d-flex flex-sm-column h-100'>
        <div className='d-none d-sm-grid' style={{ placeItems: 'center', minHeight: '7rem', minWidth: '9rem' }} aria-hidden='true'>
          { icon }
        </div>
        <div className='flex-grow-1'>
          <h2 className='bg-light rounded-1 p-2 py-1 text-dark'>{ name }</h2>
          <p className='px-2'>
            { children }
          </p>
          <div className='d-flex justify-content-end'>
            <IconButton icon={<ArrowRight size={24} aria-hidden='true' />} className='p-1 py-0 rounded-3' disabled={disabled} aria-label={`Go to ${name}`} />
          </div>
        </div>
      </article>
    </Link>
  )
}

ActionCard.propTypes = {
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
}

ActionCard.defaultProps = {
  disabled: false
}
