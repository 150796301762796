import React, { useRef, useState, useEffect } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import QualificationSelector from './QualificationSelector'

import PropTypes from '../../prop-types'
import newlineAwareSize from '../../util/newlineAwareSize'

export default function ModuleDetailsEditor ({ editMode, data, update, formErrors }) {
  const { code, name, description, length, qualification, archived, units } = data
  const descriptionLength = newlineAwareSize(description)
  const combinedLength = descriptionLength + units.reduce((acc, cur) => acc + cur.name.length, 0)

  const codeRef = useRef(null)
  const [codeSelection, setCodeSelection] = useState(null)

  const onChangeCode = ({ target }) => {
    const { value, selectionStart, selectionEnd } = target
    setCodeSelection({ start: selectionStart, end: selectionEnd })
    update({ code: value.toUpperCase() })
  }

  useEffect(() => {
    if (codeSelection === null) return
    const { start, end } = codeSelection
    codeRef.current.setSelectionRange(start, end)
  }, [codeSelection])

  return (
    <>
      <Row>
        <Col md={4} lg={3}>
          <Form.Group className='mt-3 mt-sm-2'>
            <div className='mb-1'>
              <label htmlFor='moduleCode'>Module Code</label>
              <span className={`ms-1 ${code.length < 1 || code.length > 10 ? 'text-danger' : 'text-muted'}`}>({code.length}/10)</span>
            </div>
            <Form.Control id='moduleCode' ref={codeRef} type='text' disabled={editMode} placeholder='SPL01' value={code} onChange={onChangeCode} />
            <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.code }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mt-3 mt-sm-2'>
            <div className='mb-1'>
              <label htmlFor='moduleName'>Module Name</label>
              <span className={`ms-1 ${name.length < 2 || name.length > 30 ? 'text-danger' : 'text-muted'}`}>({name.length}/30)</span>
            </div>
            <Form.Control id='moduleName' type='text' disabled={archived} placeholder='Spline Reticulation' value={name} onChange={({ target }) => update({ name: target.value })} />
            <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.name }</Form.Text>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className='mt-3 mt-sm-2'>
        <div className='mb-1'>
          <label htmlFor='moduleDescription'>Description</label>
          <span className={`ms-1 ${descriptionLength > 1000 ? 'text-danger' : 'text-muted'}`}>({descriptionLength}/1000)</span>
          <span className={`ms-1 ${combinedLength > 1300 ? 'text-danger' : 'text-primary'}`}>[{combinedLength}/1300]</span>
        </div>
        <Form.Control as='textarea' id='moduleDescription' rows={6} disabled={archived} placeholder='This module teaches the fundamentals of inventing nonsense phrases that sound like meaningful technical jargon.' value={data.description} onChange={({ target }) => update({ description: target.value })} />
        <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.description }</Form.Text>
      </Form.Group>

      <Row>
        <Col sm={5} md={4} lg={3}>
          <Form.Group className='mt-3 mt-sm-2'>
            <Form.Label className='mb-1' htmlFor='moduleLength'>
              Module Length
              { ' ' }
              <span className='text-muted'>(Days)</span>
            </Form.Label>
            <Form.Control type='number' id='moduleLength' disabled={archived} style={{ maxWidth: '5rem' }} min={1} value={length} onChange={({ target }) => update({ length: parseInt(target.value, 10) })} />
            <Form.Text className='text-danger d-block' style={{ minHeight: '1.5em' }}>{ formErrors.length }</Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <QualificationSelector className='mt-3 mt-sm-2' disabled={archived} value={qualification} onChange={(qualification) => update({ qualification })} />
        </Col>
      </Row>
    </>
  )
}

ModuleDetailsEditor.propTypes = {
  editMode: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.module.isRequired,
  formErrors: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    length: PropTypes.string
  })
}
