import React from 'react'
import { Link, useParams } from 'react-router-dom'

import Breadcrumb from 'react-bootstrap/Breadcrumb'

import HeadedPage from '../../components/HeadedPage'

import ModuleEditor from './ModuleEditor'

export default function PathEditorPage () {
  const { code } = useParams()

  const vars = {
    '--bs-link-color': 'white',
    '--bs-link-hover-color': '#c882bd',
    '--bs-breadcrumb-item-active-color': 'white'
  }

  return (
    <HeadedPage
      title={`[${code}] Module Editor`}
      headerContents={(
        <>
          <h1>Module Editor</h1>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/modules' }} style={vars}>Modules</Breadcrumb.Item>
            <Breadcrumb.Item active style={vars}>{ code }</Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
    >
      <ModuleEditor code={code} />
    </HeadedPage>
  )
}
