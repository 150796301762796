import React, { useState, useReducer } from 'react'

import clone from 'clone'
import deepEqual from 'fast-deep-equal'

import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import newlineAwareSize, { NEWLINE_SIZE } from '../../util/newlineAwareSize'

import BluecapError, { ValidationError } from '../../api/BluecapError'

import SaveButton from '../../components/SaveButton'

import StagesEditor from './StagesEditor'
import PathDetailsEditor from './PathDetailsEditor'

import PropTypes from '../../prop-types'

export default function PathForm ({
  editMode, path, isSaving, saveError, onSavePath
}) {
  const [canSave, setCanSave] = useState(false)
  const [isSaved, setSaved] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const sortStages = stages => {
    stages.sort((a, b) => a.position - b.position)
  }

  const checkCanSave = state => {
    const errors = validateState(state)
    const hasErrors = Object.values(errors).some(error => error !== undefined)
    const changed = !deepEqual(path, state)
    setCanSave(changed && !hasErrors)
  }

  const validateState = state => {
    const errors = {}

    if (state.code !== undefined) {
      const { code } = state
      if (code.length < 1 || code.length > 10) errors.code = 'Must be 1-10 characters long.'
      else if (/[^A-Z0-9-]/.test(code)) errors.code = 'May contain only A-Z, 0-9, and -'
      else errors.code = undefined
    }

    if (state.name !== undefined) {
      const { name } = state
      if (name.length < 2 || name.length > 40) errors.name = 'Must be 2-40 characters long.'
      else if (/^\s/.test(name)) errors.name = 'May not have leading whitespace.'
      else if (/\s$/.test(name)) errors.name = 'May not have trailing whitespace.'
      else errors.name = undefined
    }

    if (state.description !== undefined) {
      const { description } = state
      const length = newlineAwareSize(description)
      if (length > 1100) errors.description = `Exceeds character count. Maximum 1100 characters, line breaks count as ${NEWLINE_SIZE} characters.`
      else errors.description = undefined
    }

    return errors
  }

  sortStages(path.stages)

  const [data, update] = useReducer((state, patch) => {
    const newState = { ...state, ...patch }

    sortStages(newState.stages)
    checkCanSave(newState)
    setFormErrors({ formErrors, ...validateState(patch) })

    return newState
  }, clone(path))

  const { stages } = data

  const doSave = () => {
    onSavePath(data, () => {
      setSaved(true)
      setTimeout(() => setSaved(false), 5000)
      setCanSave(false)
    })
  }

  let errorMessage = 'An unexpected error occurred while saving the path.'
  if (saveError) {
    if (saveError instanceof ValidationError) {
      errorMessage = (
        <ul className='m-0'>
          { saveError.errors.map((error) => (
            <li key={error.codes[0]}>{ `Field "${error.field}" ${error.defaultMessage}` }</li>
          )) }
        </ul>
      )
    } else if (saveError instanceof BluecapError) {
      errorMessage = saveError.message
    }
  }

  return (
    <Form>
      { saveError && <Alert variant='danger mt-3'>{ errorMessage }</Alert> }
      { path.archived && <Alert variant='warning' className='mt-2'>This path is archived. To make changes or assign this path to a cohort, it must first be restored.</Alert> }
      <PathDetailsEditor
        editMode={editMode}
        path={data}
        update={update}
        formErrors={formErrors}
        disabled={path.archived}
      />
      { !path.archived && (
        <SaveButton
          isSaved={isSaved}
          isSaving={isSaving}
          editMode={editMode}
          disabled={!canSave || isSaving}
          onClick={doSave}
          className='mt-3'
        />
      ) }
      <StagesEditor
        disabled={path.archived}
        stages={stages}
        onChange={(nextStages) => update({ stages: nextStages })}
      />
    </Form>
  )
}

PathForm.propTypes = {
  editMode: PropTypes.bool,
  path: PropTypes.path.isRequired,
  isSaving: PropTypes.bool.isRequired,
  saveError: PropTypes.object,
  onSavePath: PropTypes.func.isRequired
}

PathForm.defaultProps = {
  editMode: false,
  saveError: null
}
