export default class BluecapError extends Error {
  constructor ({ code, message, details }) {
    super(message)
    this.code = code
    this.details = details
  }

  static from (data) {
    switch (data.code) {
    case 'archived-dependencies':
      return new ArchivedDependenciesError(data)
    case 'csv-write-failure':
      return new CSVWriteError(data)
    case 'index-collision':
      return new IdCollisionError(data)
    case 'index-mutation':
      return new IdMutationError(data)
    case 'ppt-write-failure':
      return new PPTWriteError(data)
    case 'validation-error':
      return new ValidationError(data)
    case 'resource-not-found':
      return new ResourceNotFoundError(data)
    case 'search-indexing-failure':
      return new SearchIndexingError(data)
    case 'unexpected-error':
      return new UnexpectedError(data)
    case 'unarchived-dependents':
      return new UnarchivedDependentsError(data)
    default:
      return new BluecapError(data)
    }
  }
}

export class ArchivedDependenciesError extends BluecapError {
  constructor (data) {
    super(data)
    this.modules = data.details.modules
  }
}

export class CSVWriteError extends BluecapError {}

export class IdCollisionError extends BluecapError {
  constructor (data) {
    super(data)
    this.resourceName = data.details.resourceName
    this.id = data.details.id
  }
}

export class IdMutationError extends BluecapError {
  constructor (data) {
    super(data)
    this.resourceName = data.details.resourceName
    this.id = data.details.id
  }
}

export class PPTWriteError extends BluecapError {}

export class ValidationError extends BluecapError {
  constructor (data) {
    super(data)
    this.errors = data.details.errors
  }
}

export class ResourceNotFoundError extends BluecapError {
  constructor (data) {
    super(data)
    this.resourceName = data.details.resourceName
    this.id = data.details.id
  }
}

export class SearchIndexingError extends BluecapError {}

export class UnexpectedError extends BluecapError {}

export class UnarchivedDependentsError extends BluecapError {
  constructor (data) {
    super(data)
    this.paths = data.details.paths
  }
}
