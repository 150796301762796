import React, { useState } from 'react'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { Search, XLg } from 'react-bootstrap-icons'

import IconButton from './IconButton'

import PropTypes from '../prop-types'

export default function SearchBar ({ value, onSubmit, onUpdate, ...props }) {
  const [searchTerm, setSearchTerm] = useState(value)

  const doSubmit = (event) => {
    event.preventDefault()
    onSubmit(searchTerm)
  }

  const onChange = ({ target }) => {
    if (onUpdate !== undefined) onUpdate(target.value)
    setSearchTerm(target.value)
  }

  const onClear = () => {
    setSearchTerm('')
    onSubmit('')
  }

  return (
    <Form onSubmit={doSubmit} {...props}>
      <InputGroup>
        <Form.Control type='text' value={searchTerm} onChange={onChange} aria-label='Search terms' placeholder='Search terms' />
        { value !== '' && <IconButton type='button' variant='light' icon={<XLg aria-hidden />} aria-label='Clear search' onClick={onClear} /> }
        <IconButton type='submit' icon={<Search aria-hidden />} aria-label='Search' />
      </InputGroup>
    </Form>
  )
}

SearchBar.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func
}

SearchBar.defaultProps = {
  value: '',
  onUpdate: undefined
}
