import PropTypes from 'prop-types'

import module from './module'
import path from './path'
import pathStage from './pathStage'
import qualification from './qualification'
import unit from './unit'

export default {
  ...PropTypes,
  module,
  path,
  pathStage,
  qualification,
  unit
}
