import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
  MortarboardFill, GeoAltFill, AwardFill, PuzzleFill, PeopleFill
} from 'react-bootstrap-icons'

import HeadedPage from '../../components/HeadedPage'
import ActionCard from './ActionCard'

export default function Home () {
  return (
    <HeadedPage headerContents={(
      <h1 className='display-1 d-flex align-items-center gap-4'>
        <MortarboardFill color='hsl(209, 94%, 65%)' aria-hidden />
        Bluecap
      </h1>
    )}
    >
      <Row sm={1} md={2} lg={4} className='gy-3 gx-3'>
        <Col>
          <ActionCard name='Pathways' href='/paths' icon={<GeoAltFill size={80} color='var(--bs-primary)' />}>
            View, build and edit training pathways.
          </ActionCard>
        </Col>
        <Col>
          <ActionCard name='Modules' href='/modules' icon={<PuzzleFill size={96} color='var(--bs-primary)' />}>
            View, build and edit modules of taught content.
          </ActionCard>
        </Col>
        <Col>
          <ActionCard name='Qualifications' href='/qualifications' icon={<AwardFill size={80} color='var(--bs-primary)' />}>
            Add, update and delete courses and exams delivered as part of TSI training pathways.
          </ActionCard>
        </Col>
        <Col>
          <ActionCard disabled name='Cohorts' href='/cohorts' icon={<PeopleFill size={96} color='var(--bs-primary)' />}>
            Manage cohorts and trainees.
          </ActionCard>
        </Col>
      </Row>
    </HeadedPage>
  )
}
