import api from './api'

export default {
  modules: {
    async suggestFirst (limit) {
      return api.list('cappy/modules/suggestions/first', {
        limit: limit ?? 3
      })
    },

    async suggestNext (moduleCode, limit) {
      return api.list('cappy/modules/suggestions/next', {
        after: moduleCode,
        limit: limit ?? 3
      })
    }
  }
}
