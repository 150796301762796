import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'

import { ArchiveFill, Hourglass, BoxArrowUpRight } from 'react-bootstrap-icons'

import IconButton from './IconButton'
import Button from './Button'

import { UnarchivedDependentsError } from '../api/BluecapError'

export default function ArchiveButton ({ onClick, isLoading, isError, error }) {
  const [showModal, setShowModal] = useState(false)

  const hideModal = () => setShowModal(false)

  useEffect(() => {
    setShowModal(isError && error instanceof UnarchivedDependentsError)
  }, [isError, error, setShowModal])

  return (
    <>
      <IconButton variant='outline-red' icon={isLoading ? <Hourglass aria-label='Loading' /> : <ArchiveFill aria-hidden />} onClick={onClick} disabled={isLoading}>
        Archive
      </IconButton>
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Archiving Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This module cannot be archived because there are unarchived training pathways that include it:</p>
          <ul>
            { error?.paths?.map(
              pathCode => (
                <li key={pathCode}>
                  <Link to={`/paths/edit/${pathCode}`} target='_blank'>
                    [{ pathCode }]
                    <BoxArrowUpRight className='text-decoration-none ms-2 position-relative' style={{ top: '-3px' }} />
                  </Link>
                </li>
              )
            ) }
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideModal}>Okay</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

ArchiveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error)
}
