import React, { useRef, useState, useEffect } from 'react'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import InputGroup from 'react-bootstrap/InputGroup'

import SaveButton from '../../components/SaveButton'

import BluecapError, { ValidationError } from '../../api/BluecapError'

import PropTypes from '../../prop-types'
import iso4712, { preferredCurrencies } from '../../api/iso4712'

export default function QualificationForm ({ editMode, formData, update, isChanged, onSave, isSaving, isSaved, saveError }) {
  const { code, name, typeName, canBuyInBulk, description, hasLink, link } = formData

  const codeRef = useRef(null)
  const [codeSelection, setCodeSelection] = useState(null)

  const onChangeCode = ({ target }) => {
    const { value, selectionStart, selectionEnd } = target
    setCodeSelection({ start: selectionStart, end: selectionEnd })
    update({ code: value.toUpperCase() })
  }

  useEffect(() => {
    if (codeSelection === null) return
    const { start, end } = codeSelection
    codeRef.current.setSelectionRange(start, end)
  }, [codeSelection])

  let errorMessage = 'An unexpected error occurred while saving the path.'
  if (saveError) {
    if (saveError instanceof ValidationError) {
      errorMessage = (
        <ul className='m-0'>
          { saveError.errors.map((error) => (
            <li key={error.codes[0]}>{ `Field "${error.field}" ${error.defaultMessage}` }</li>
          )) }
        </ul>
      )
    } else if (saveError instanceof BluecapError) {
      errorMessage = saveError.message
    }
  }

  return (
    <Form>
      {
        editMode
          ? <h2><span className='text-muted'>{`[${formData.code}] `}</span>{ formData.name }</h2>
          : <h2>New Qualification</h2>
      }

      { saveError && <Alert variant='danger'>{ errorMessage }</Alert> }

      <Row className='mb-2'>
        <Col md={4} lg={3}>
          <Form.Group>
            <div className='form-label mb-1'>
              <label htmlFor='qualificationCode'>Qualification Code</label>
              <span className={`ms-1 ${code.length < 1 || code.length > 20 ? 'text-danger' : 'text-muted'}`}>({code.length}/20)</span>
            </div>
            <Form.Control id='qualificationCode' ref={codeRef} type='text' disabled={editMode} placeholder='01-BIV' value={code} onChange={onChangeCode} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mt-2 mt-md-0'>
            <div className='form-label mb-1'>
              <label htmlFor='qualificationName'>Qualification Name</label>
              <span className={`ms-1 ${name.length < 2 || name.length > 100 ? 'text-danger' : 'text-muted'}`}>({name.length}/100)</span>
            </div>
            <Form.Control id='qualificationName' type='text' placeholder='Badge #1: Bivouacking' value={name} onChange={({ target }) => update({ name: target.value })} />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col sm md={3} lg={2}>
          <Form.Group>
            <Form.Label htmlFor='qualificationType'>Qualification Type</Form.Label>
            <Form.Select id='qualificationType' value={typeName} onChange={({ target }) => update({ typeName: target.value })}>
              <option value='EXAM'>Exam</option>
              <option value='COURSE'>Course</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm md={6} lg={4} className='mt-2 mt-sm-0'>
          <Form.Label htmlFor='qualificationUnitPrice'>Unit Price</Form.Label>
          <InputGroup>
            <Form.Control id='qualificationUnitPrice' type='number' min={0} step='any' value={formData.unitPrice} onChange={({ target }) => update({ unitPrice: Math.max(0, target.value) })} />
            <Form.Select id='qualificationUnitCurrency' aria-label='Currency' value={formData.unitPriceCurrency} onChange={({ target }) => update({ unitPriceCurrency: target.value })}>
              {
                preferredCurrencies.map(({ code, currency }) => (
                  <option key={code} value={code}>{ code }: {currency}</option>
                ))
              }
              <option disabled aria-hidden style={{ color: 'var(--bs-gray-400)' }}>─────</option>
              { iso4712.map(cur => (
                <option key={cur.code} value={cur.code}>{ cur.code }: { cur.currency }</option>
              )) }
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      <div className='d-flex align-items-center mt-3 justify-content-between'>
        <Form.Check
          reverse
          type='switch'
          label='Can buy in bulk?'
          aria-label='Can buy in bulk?'
          checked={canBuyInBulk}
          onChange={({ target }) => update({ canBuyInBulk: target.checked })}
        />
        <SaveButton
          editMode={editMode}
          isSaved={isSaved}
          isSaving={isSaving}
          disabled={!isChanged || isSaving}
          onClick={() => onSave()}
        />
      </div>

      <Form.Group className='mt-2'>
        <div className='form-label mb-1'>
          <label htmlFor='qualificationDescription'>Description</label>
          <span className={`ms-1 ${(description && description.length > 750) ? 'text-danger' : 'text-muted'}`}>({description?.length ?? 0}/750)</span>
        </div>
        <Form.Control as='textarea' id='qualificationDescription' value={description} onChange={({ target }) => update({ description: target.value })} />
      </Form.Group>

      <Form.Check
        id='qualificationHasLink'
        type='switch'
        className='mt-4'
        checked={hasLink}
        onChange={({ target }) => update({ hasLink: target.checked })}
        aria-label='Include link to external site'
        label='Include link to external site'
      />

      { formData.hasLink && (
        <Form.Group className='mt-2 bg-light border rounded shadow-sm p-2'>
          <div className='form-label mb-1'>
            <label htmlFor='qualificationLink'>Link to external site</label>
            <span className={`ms-1 ${link.length > 200 ? 'text-danger' : 'text-muted'}`}>({link.length}/200)</span>
          </div>
          <Form.Control
            id='qualificationLink'
            type='text'
            placeholder='https://example.com'
            value={link}
            onChange={({ target }) => update({ link: target.value })}
            disabled={!hasLink}
          />
        </Form.Group>
      ) }
    </Form>
  )
}

QualificationForm.propTypes = {
  editMode: PropTypes.bool,
  formData: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    typeName: PropTypes.string.isRequired,
    unitPrice: PropTypes.string.isRequired,
    unitPriceCurrency: PropTypes.string.isRequired,
    canBuyInBulk: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    hasLink: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired
  }),
  update: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
  saveError: PropTypes.object
}

QualificationForm.defaultProps = {
  editMode: false,
  saveError: null
}
