import React from 'react'
import PropTypes from 'prop-types'

import Pagination from 'react-bootstrap/Pagination'
import Select from 'react-bootstrap/FormSelect'
import { FilterRight } from 'react-bootstrap-icons'

export default function Paginator ({
  page, links, onSetPage, onSetSize, className
}) {
  const currentPage = (page?.number ?? 0) + 1

  const hasPrev = links?.prev !== undefined
  const hasNext = links?.next !== undefined
  const hasFirst = links?.first !== undefined && hasPrev
  const hasLast = links?.last !== undefined && hasNext

  const first = 1
  const prev = currentPage - 1
  const self = currentPage
  const next = currentPage + 1
  const last = page?.totalPages ?? 1

  return (
    <div className={`d-flex flex-wrap gap-2 gap-sm-4 justify-content-between justify-content-md-start ${className}`}>
      <Pagination className='mb-0'>
        <Pagination.First disabled={!hasFirst} onClick={() => onSetPage(first)} aria-label='First page' tabIndex='0' role='button' />
        <Pagination.Prev disabled={!hasPrev} onClick={() => onSetPage(prev)} aria-label='Previous page' tabIndex='0' role='button' />
        { hasPrev
          ? <Pagination.Item onClick={() => onSetPage(prev)} aria-label={`Go to page ${prev}`} tabIndex='0' role='button'>
              { prev }
            </Pagination.Item>
          : <Pagination.Item disabled style={{ '--bs-pagination-disabled-color': 'white' }} aria-hidden>0</Pagination.Item> }

        <Pagination.Item active onClick={() => onSetPage(self)} aria-label={`Go to current page ${self}`} tabIndex='0' role='button'>
          { self }
        </Pagination.Item>

        { hasNext
          ? <Pagination.Item onClick={() => onSetPage(next)} aria-label={`Go to page ${next}`} tabIndex='0' role='button'>
              { next }
            </Pagination.Item>
          : <Pagination.Item disabled style={{ '--bs-pagination-disabled-color': 'white' }} aria-hidden>0</Pagination.Item> }
        <Pagination.Next disabled={!hasNext} onClick={() => onSetPage(next)} aria-label='Next page' tabIndex='0' role='button' />
        <Pagination.Last disabled={!hasLast} onClick={() => onSetPage(last)} aria-label='Last page' tabIndex='0' role='button' />
      </Pagination>
      <label className='d-flex align-items-center gap-2'>
        <FilterRight size={32} aria-label='Items per Page:' className='d-inline d-sm-none flex-shrink-0' />
        <span className='d-none d-sm-inline flex-shrink-0'>Items per Page:</span>
        <Select value={page?.size} onChange={({ target }) => onSetSize(target.value)}>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </Select>
      </label>
    </div>
  )
}

const Link = PropTypes.shape({
  href: PropTypes.string.isRequired
})

Paginator.propTypes = {
  page: PropTypes.shape({
    number: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired
  }),
  links: PropTypes.shape({
    self: Link.isRequired,
    next: Link,
    prev: Link,
    first: Link,
    last: Link
  }),
  onSetPage: PropTypes.func.isRequired,
  onSetSize: PropTypes.func.isRequired,
  className: PropTypes.string
}

Paginator.defaultProps = {
  className: '',
  page: undefined,
  links: undefined
}
