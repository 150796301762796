import React, { createContext, useContext, useReducer, useMemo } from 'react'

import ToastContainer from 'react-bootstrap/ToastContainer'
import Toast from 'react-bootstrap/Toast'

import PropTypes from '../prop-types'

const ToastContext = createContext([])

export function useToaster () {
  return useContext(ToastContext)
}

export function ToastProvider ({ children }) {
  const [toasts, dispatch] = useReducer((currentToasts, [action, payload]) => {
    const nextToasts = [...currentToasts]

    switch (action) {
    case 'push':
      nextToasts.push(payload)
      break
    case 'remove':
      nextToasts.splice(payload, 1)
      break
    }

    return nextToasts
  }, [])

  const value = useMemo(() => ({
    toasts,
    push: (toast) => dispatch(['push', toast]),
    remove: (index) => dispatch(['remove', index])
  }), [toasts])

  return (
    <ToastContext.Provider value={value}>
      { children }
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default function Toaster () {
  const toaster = useToaster()

  return (
    <ToastContainer className='position-absolute end-0 bottom-0 m-2'>
      { toaster.toasts.map((toast, idx) => (
        <Toast key={idx} bg={toast.variant} onClose={() => toaster.remove(idx)}>
          <Toast.Header className={`text-${toast.variant} gap-2`}>
            { toast.icon }
            <strong className='me-auto'>{toast.title}</strong>
          </Toast.Header>
          <Toast.Body className={['danger', 'dark'].includes(toast.variant) && 'text-white'}>{toast.message}</Toast.Body>
        </Toast>
      )) }
    </ToastContainer>
  )
}
