import { useQueryClient, useMutation } from 'react-query'

import modules from '../api/modules'

export default function useModuleArchiving (moduleCode, options) {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(['module'])
    queryClient.invalidateQueries(['module', moduleCode])
    options?.onSuccess?.()
  }

  const { mutate: onArchive, isLoading: isArchiving, isError: isArchiveError, error: archiveError } = useMutation(
    ['module', moduleCode],
    () => modules.update(moduleCode, { archived: true }),
    { onSuccess }
  )

  const { mutate: onRestore, isLoading: isRestoring, isError: isRestoreError, error: restoreError } = useMutation(
    ['module', moduleCode],
    () => modules.update(moduleCode, { archived: false }),
    { onSuccess }
  )

  return {
    isArchiving,
    isRestoring,
    isError: isArchiveError || isRestoreError,
    error: archiveError ?? restoreError,
    onArchive,
    onRestore
  }
}
