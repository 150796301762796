import React, { useState } from 'react'

import { Draggable, useDraggable } from 'react-tiny-dnd'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { BoxArrowUpRight, ExclamationOctagonFill, Trash } from 'react-bootstrap-icons'

import Grip from '../../components/Grip'
import BumpButton from '../../components/BumpButton'
import IconButton from '../../components/IconButton'
import AutoTruncated from '../../components/AutoTruncated'
import QualificationBadge from '../../components/QualificationBadge'

import PropTypes from '../../prop-types'

export default function ModuleCard ({
  disabled, stage, context, index, onMove, onRemove, className
}) {
  const [isDeleting, setDeleting] = useState(false)

  const promptDelete = () => {
    setDeleting(true)
    setTimeout(() => {
      setDeleting(false)
    }, 5000)
  }

  const doDelete = () => {
    onRemove()
  }

  const { listeners, isDragging } = useDraggable(context, index)

  const { module, position } = stage

  return (
    <Draggable
      key={module.code}
      context={context}
      index={index}
    >
      <Row className={`m-0 ${className}`} style={{ opacity: isDragging ? '0.3' : 1 }}>
        { !disabled && (
          <Col xs='auto' className='p-0'>
            <div className='h-100 ps-1 pe-2 d-flex flex-column gap-1 justify-content-center align-items-center'>
              <BumpButton dir='up' onClick={() => onMove(index - 1)} />
              <Grip aria-label='Move module by dragging' {...listeners} />
              <BumpButton dir='down' onClick={() => onMove(index + 1)} />
            </div>
          </Col>
        ) }
        <Col className='p-0 user-select-none' style={{ minWidth: 0 }}>
          <Card>
            <Card.Body className='p-2'>
              <Card.Title className='bg-light p-2 rounded-1 ps-3'>
                <span className='text-primary'>{ `#${position + 1} ` }</span>
                <span className='text-muted'>{ `[${module.code}] ` }</span>
                { module.name }
                { module.qualification && (
                  <QualificationBadge qualification={module.qualification} size={20} />
                ) }
              </Card.Title>
              <Card.Text as='div' className='mb-0 px-2'>
                <AutoTruncated as='p' className='my-0'>
                  { module.description }
                </AutoTruncated>
                <AutoTruncated as='p' className='my-0'>
                  <span className='fw-semibold'>Length:</span>
                  { ' ' }
                  { module.length }
                </AutoTruncated>
                <AutoTruncated as='p' className='my-0'>
                  <span className='fw-semibold'>Includes:</span>
                  { ' ' }
                  { module.units.map((unit) => unit.name).join(', ') }
                </AutoTruncated>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs='auto' className='p-0 d-flex flex-column gap-1 ps-2'>
          <OverlayTrigger
            delay={250}
            overlay={(
              <Tooltip id='tooltip-module-link'>Go to module</Tooltip>
            )}
          >
            <IconButton aria-label='View module in new tab' icon={<BoxArrowUpRight aria-hidden />} variant='primary' className='p-2' href={`/modules/edit/${module.code}`} target='_blank' />
          </OverlayTrigger>
          { !disabled && (
            <OverlayTrigger
              delay={250}
              placement='bottom'
              overlay={(
                <Tooltip id='tooltip-remove-module' aria-hidden>Remove from path</Tooltip>
              )}
            >
              { !isDeleting
                ? <IconButton aria-label='Remove module' icon={<Trash aria-hidden />} variant='outline-danger' className='p-2' onClick={promptDelete} />
                : <IconButton aria-label='Confirm remove module' icon={<ExclamationOctagonFill aria-hidden />} variant='danger' className='p-2' onClick={doDelete} /> }
            </OverlayTrigger>
          ) }
        </Col>
      </Row>
    </Draggable>
  )
}

ModuleCard.propTypes = {
  disabled: PropTypes.bool,
  stage: PropTypes.pathStage.isRequired,
  context: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  className: PropTypes.string
}

ModuleCard.defaultProps = {
  disabled: false,
  className: undefined
}
