import React from 'react'
import ModuleCard from './ModuleCard'

import PropTypes from '../../prop-types'

export default function ModulesList ({ modules, isRefetching }) {
  return (
    <div className='position-relative'>
      { modules.map((module) => (
        <ModuleCard key={module.code} module={module} />
      )) }
      { /* Overlay to prevent interaction & indicate inactivity during refetching */ }
      { /* Should reduce chance of layout shifts causing misclicks. */ }
      { isRefetching && <div className='position-absolute top-0 left-0 w-100 h-100 rounded bg-light opacity-50' aria-hidden /> }
    </div>
  )
}

ModulesList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.module).isRequired,
  isRefetching: PropTypes.bool.isRequired
}
