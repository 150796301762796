import React, { useState } from 'react'

import { useQuery } from 'react-query'

import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

import { Plus } from 'react-bootstrap-icons'

import qualifications from '../../api/qualifications'
import BluecapError from '../../api/BluecapError'

import HeadedPage from '../../components/HeadedPage'
import SearchBar from '../../components/SearchBar'
import LinkIconButton from '../../components/LinkIconButton'

import QualificationCard from './QualificationCard'

export default function QualificationsPage () {
  const [searchTerm, setSearchTerm] = useState(undefined)

  const { isLoading, isSuccess, data, isError, error } = useQuery(['qualifications'], () => qualifications.list())

  const qualificationList = data
    ?._embedded
    ?.qualificationList
    ?.filter(q => {
      if (searchTerm === undefined || searchTerm.length === 0) return true
      const needle = searchTerm.toUpperCase()
      return q.code.includes(needle)
        || q.name.toUpperCase().includes(needle)
        || q.description?.toUpperCase()?.includes(needle)
    })

  return (
    <HeadedPage
      headerContents={(
        <>
          <div className='d-flex align-items-center'>
            <h1 className='me-auto'>Qualifications</h1>
            <LinkIconButton icon={<Plus size={32} aria-hidden />} variant='green' className='p-1 pe-3' to='/qualifications/builder'>Add Qualification</LinkIconButton>
          </div>
          <div className='d-flex mt-2'>
            <SearchBar value={searchTerm} onUpdate={(value) => setSearchTerm(value)} onSubmit={(value) => setSearchTerm(value)} className='ms-auto flex-grow-1 flex-md-grow-0' style={{ minWidth: '320px' }} />
          </div>
          { isSuccess && (
            <div className='text-light mt-2 text-end'>{ `${qualificationList?.length ?? 'No'} results found.` }</div>
          ) }
        </>
      )}
    >
      { isLoading && (
        <Alert variant='info'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Spinner size='sm' variant='info' />
            <span>Loading...</span>
          </div>
        </Alert>
      ) }

      { isError && (
        <Alert variant='danger'>
          {
            error instanceof BluecapError
              ? error.message
              : 'Failed to fetch qualifications.'
          }
        </Alert>
      ) }

      { isSuccess && (
        qualificationList === undefined
          ? <Alert variant='warning'>No qualifications found.</Alert>
          : qualificationList.map(qualification => (
            <QualificationCard key={qualification.code} qualification={qualification} />
          ))
      ) }
    </HeadedPage>
  )
}
