import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useLocation } from 'react-router-dom'

import { List } from 'react-bootstrap-icons'
import { Alert, Button, Container, Spinner } from 'react-bootstrap'

import { authEnabled } from '../api/auth'
import { useMsal } from '@azure/msal-react'

import NavMenu, { useNavMenu } from './NavMenu'
import IconButton from './IconButton'
import Toaster from './Toaster'

import useTitle from '../hooks/useTitle'

export default function Page ({ title, children, requiresAuth = true }) {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = accounts.length > 0
  const authenticationRequired = authEnabled && requiresAuth && !isAuthenticated
  useTitle(title)

  const navMenu = useNavMenu()

  const location = useLocation()

  useEffect(() => {
    navMenu.hide()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <main className='bg-light pb-4' style={{ minHeight: '100vh' }}>
        <IconButton
          icon={<List size={32} aria-hidden='true' />}
          onClick={navMenu.show}
          variant='primary'
          className='d-sm-none position-fixed p-2 rounded'
          aria-label='Menu'
          tabIndex='0'
          style={{
            bottom: '0.5rem',
            right: '0.5rem',
            zIndex: 1030
          }}
        />
        <IconButton
          icon={<List size={20} aria-hidden='true' />}
          onClick={navMenu.show}
          variant='primary'
          className='d-none d-sm-flex position-fixed p-0 rounded-1'
          aria-label='Menu'
          style={{
            top: '0.5rem',
            left: '0.5rem',
            width: '2rem',
            height: '2rem',
            zIndex: 1030
          }}
        />
        { !authenticationRequired && children }
        { authenticationRequired && (
          <Container className='pt-5'>
            {
              inProgress === 'login'
                ? <Alert variant='info'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <Spinner size='sm' variant='info' />
                      <span>Waiting for sign in to finish...</span>
                    </div>
                  </Alert>
                : <div className='rounded shadow bg-white p-4'>
                    <h2>Authentication Required</h2>
                    <p>In order to proceed, you must <Button variant='primary' onClick={() => instance.loginPopup()}>Sign In</Button>.</p>
                  </div>
            }
          </Container>
        )}
      </main>
      <NavMenu />
      <Toaster />
    </>
  )
}

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  requiresAuth: PropTypes.bool
}

Page.defaultProps = {
  title: undefined,
  children: undefined,
  requiresAuth: true
}
