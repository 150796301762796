import axios, { AxiosError } from 'axios'
import BluecapError from './BluecapError'
import { acquireAccessToken, authEnabled } from './auth'

let baseURL = process.env.API_BASE_URL
if (baseURL === undefined) {
  if (process.env.NODE_ENV === 'production') {
    baseURL = `${process.env.PUBLIC_URL}/api`
  } else {
    baseURL = 'http://localhost:8080/api'
  }
}

const _http = async () =>
  axios.create({
    baseURL,
    headers: {
      Authorization: authEnabled ? `Bearer ${await acquireAccessToken()}` : undefined,
      'Content-Type': 'application/json'
    }
  })

function handleError (error) {
  if (!(error instanceof AxiosError)) throw error

  const { status, data } = error.response

  if (status === 401) {
    throw new Error('The API is unhappy with the JWT. Perhaps the audience is not correct?')
  }
  if (status === 403) {
    throw new Error('User not authenticated to perform this action. Check their role assignments.')
  }

  if (data.error) throw BluecapError.from(data.error)

  throw new Error('An unexpected error ocurred')
}

const api = {
  baseUrl () {
    return baseURL
  },

  async http () {
    return await _http()
  },

  async list (collection, params) {
    try {
      const url = `${collection}?${new URLSearchParams(params)}`
      const http = await _http()
      const res = await http.get(url)
      const { data } = res
      return data
    } catch (cause) {
      handleError(cause)
      return null
    }
  },

  async get (collection, resourceId) {
    try {
      const url = `${collection}/${resourceId}`
      const http = await _http()
      const res = await http.get(url)
      const { data } = res
      return data
    } catch (cause) {
      handleError(cause)
      return null
    }
  },

  async post (collection, payload) {
    try {
      const http = await _http()
      const res = await http.post(collection, payload)
      const { data } = res

      return { res, data }
    } catch (error) {
      handleError(error)
      return null
    }
  },

  async patch (collection, resourceId, payload) {
    try {
      const url = `${collection}/${resourceId}`
      const http = await _http()
      const res = await http.patch(url, payload)
      const { data } = res

      return { res, data }
    } catch (error) {
      handleError(error)
      return null
    }
  },

  async delete (collection, resourceId) {
    try {
      const url = `${collection}/${resourceId}`
      const http = await _http()
      const res = await http.delete(url)
      return res
    } catch (error) {
      handleError(error)
      return null
    }
  }
}

export default api
