import React from 'react'

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'

import PropTypes from '../../prop-types'

export default function PathCardModuleList ({ stages }) {
  stages.sort((a, b) => a.position - b.position)

  return (
    <Breadcrumb style={{ '--bs-breadcrumb-divider': '">"' }}>
      { stages.map((stage) => (
        <Breadcrumb.Item key={stage.position} linkAs={Link} linkProps={{ to: `/modules/edit/${stage.module.code}` }} className='fw-semibold'>
          { stage.module.name }
        </Breadcrumb.Item>
      )) }
    </Breadcrumb>
  )
}

PathCardModuleList.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.pathStage).isRequired
}
